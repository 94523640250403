
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import { getModule } from "vuex-module-decorators";
import { StoreAssignments, StoreAuthentication, StoreClipboard } from "@/store";
import { LivingAppsAssignmentRecord, LivingAppsAssignmentControl } from "@/types";
import ReportsDetailData from "@/components/ReportsDetailData.vue";
import ReportsForm from "@/components/ReportsForm.vue";
import AssignmentDetailNav from "@/components/AssignmentDetailNav.vue";
import LoginForm from "@/components/LoginForm.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import ClipboardButton from "@/components/ClipboardButton.vue";
import { EventBus } from "@/main";

@Component({
  components: {
    ReportsDetailData,
    ReportsForm,
    AssignmentDetailNav,
    ActiveAssignmentButton,
    ClipboardButton,
  },
})
export default class ReportsDetail extends Mixins(GlobalUser, ClipboardUtils) {
  public storeAssignments = StoreAssignments;
  public assignmentRecord?: LivingAppsAssignmentRecord | null = null;
  public record?: LivingAppsAssignmentRecord | null = null;
  public controls?: LivingAppsAssignmentControl | null;
  public type: string = "maintenance";
  public edit: boolean = false;

  public recAudio: boolean = false;

  public get detailNavRoutes() {
    const id: string = this.$route.params.id;
    const type: string = this.type;
    return {
      infos: { name: `${this.type}_detail`, params: { id, type } },
      reports: { name: "reports", params: { id, type } },
      consumptions: { name: "consumptions", params: { id, type } },
      employees: { name: "employees", params: { id, type } },
      edit: { name: "", params: { id, type } },
    };
  }

  public get filterRecords() {
    for (const record of this.storeAssignments.getRecords) {
      if (record.id === this.$route.params.report_id) {
        this.record = record;
        this.assignmentRecord = this.setAssignmentRecord();
        return this.record;
      }
    }
    return null;
  }

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public get hasAuthority() {
    if (this.costcentre) {
      return true;
    }
    if (this.record && this.record.angelegt_von) {
      const employeeSymbol = this.getUserSymbol();
      const createdBy = (this.record.angelegt_von.value as string).trim().toLowerCase();
      if (createdBy === employeeSymbol) {
        return true;
      }
    }
    return false;
  }

  public setAssignmentRecord() {
    if (this.record) {
      for (const record of this.storeAssignments.getRecords) {
        if (record.auftrag_id && this.record.auftrag_id && record.typ && this.record.typ) {
          const currId: string = record.auftrag_id.value as string;
          const assignmentId: string = this.record.auftrag_id.value as string;
          const typ: string = record.typ.value[0].key;
          if (typ !== "bericht" && assignmentId === currId) {
            return record;
          }
        }
      }
      return undefined;
    }
  }

  public handleControls() {
    this.controls = this.storeAssignments.getControls;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public editRecord() {
    this.edit = true;
  }

  public formFinish() {
    const refForm: any = this.$refs.reportsFormComp;
    if (refForm.isDirty) {
      EventBus.$emit("dirtyForm", this, refForm);
    } else {
      this.edit = false;
    }
  }

  public formCancel() {
    const refForm: any = this.$refs.reportsFormComp;
    this.edit = false;
    refForm.isDirty = false;
    StoreClipboard.resetAssignedQueue();
  }

  public formSaved() {
    const refForm: any = this.$refs.reportsFormComp;
    this.edit = false;
    refForm.isDirty = false;
    this.handleClipboardCleanup();
  }

  public handleRecordStarted() {
    this.recAudio = true;
  }

  public handleRecordCompleted() {
    this.recAudio = false;
  }

  protected mounted() {
    this.handleControls();
  }
}
