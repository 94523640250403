
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store, { StoreAssignments, StoreConsumptions, StoreAuthentication } from "@/store";
import {
  LivingAppsAssignmentRecord,
  LivingAppsConsumptionRecord,
  LivingAppsConsumptionControl,
  LivingAppsAssignmentControl,
} from "@/types";
import ConsumptionsForm from "@/components/ConsumptionsForm.vue";
import GrabBagList from "@/components/GrabBagList.vue";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import AssignmentDetailNav from "@/components/AssignmentDetailNav.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import { EventBus } from "@/main";
import { ActionSheetController } from "@ionic/vue/dist/types/controllers";

@Component({
  components: {
    ConsumptionsForm,
    GrabBagList,
    AssignmentDetailNav,
    ActiveAssignmentButton,
    NoListEntriesMessage,
  },
})
export default class Consumptions extends Vue {
  public storeConsumptions = StoreConsumptions;
  public record?: LivingAppsAssignmentRecord | null;
  public controls?: LivingAppsAssignmentControl | null;
  public edit: boolean = false;
  public grapbag: boolean = false;
  public type: string = "maintenance";

  public get detailNavRoutes() {
    const id: string = this.$route.params.id;
    const type: string = this.type;
    return {
      infos: { name: `${this.type}_detail`, params: { id, type } },
      reports: { name: "reports", params: { id, type } },
      consumptions: { name: "consumptions", params: { id, type } },
      employees: { name: "employees", params: { id, type } },
      edit: { name: "", params: { id, type } },
    };
  }

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public isTime(record: LivingAppsConsumptionRecord): boolean {
    if (record.dauer) {
      return true;
    }
    return false;
  }

  public get records(): LivingAppsConsumptionRecord[] {
    const realRecords: LivingAppsConsumptionRecord[] = [];
    const records: LivingAppsConsumptionRecord[] = this.storeConsumptions.getRecords;
    const assignmentRecord = this.getAssignmentRecord();
    if (assignmentRecord) {
      this.record = assignmentRecord;
      const assignmentId = assignmentRecord.auftrag_id ? assignmentRecord.auftrag_id.value : null;
      if (assignmentId) {
        for (const record of records) {
          const consumptionId: any = record.auftrag_id ? record.auftrag_id.value : null;
          if (assignmentId === consumptionId) {
            if (record.lap_id || record.dauer) {
              realRecords.push(record);
            }
          }
        }
      }
    }
    return realRecords;
  }

  public floatToStr(num: number): string {
    if (num) {
      return (num + "").replace(/\./g, ",");
    }
    return "0";
  }

  public getAssignmentRecord(): LivingAppsAssignmentRecord | null {
    const recordId: string = this.$route.params.id;
    for (const record of StoreAssignments.getRecords) {
      if (record.id === recordId) {
        return record;
      }
    }
    return null;
  }

  public handleControls() {
    this.controls = StoreAssignments.getControls;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public newRecord() {
    if (this.costcentre) {
      this.edit = true;
      this.grapbag = false;
    } else {
      const controller: HTMLIonActionSheetControllerElement = document.querySelector(
        "ion-action-sheet-controller"
      ) as HTMLIonActionSheetControllerElement;
      controller
        .create({
          header: "Verbrauchstyp",
          buttons: [
            {
              text: "Zeiteintrag",
              icon: "clock",
              handler: () => {
                this.edit = true;
                this.grapbag = false;
              },
            },
            {
              text: "Material",
              icon: "cube",
              handler: () => {
                this.edit = false;
                this.grapbag = true;
              },
            },
            { text: "Abbrechen", role: "cancel" },
          ],
        })
        .then((actionSheet) => {
          actionSheet.present();
        });
    }
  }

  public formFinish() {
    const refForm: any = this.$refs.consumptionsFormComp;
    if (refForm.isDirty) {
      EventBus.$emit("dirtyForm", this, refForm);
    } else {
      this.edit = false;
    }
  }

  public formCancel() {
    const refForm: any = this.$refs.consumptionsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  public formSaved() {
    const refForm: any = this.$refs.consumptionsFormComp;
    this.edit = false;
    refForm.isDirty = false;
  }

  public grapbagSaved() {
    // todo
  }

  public grapbagFinish() {
    this.grapbag = false;
  }

  public deleteCancel(recordId: string) {
    const elm = document.getElementById("record-list") as HTMLIonListElement;
    if (elm) {
      elm.closeSlidingItems();
    }
  }

  public async deleteItem(recordId: string) {
    const elm = document.getElementById("record-list") as HTMLIonListElement;
    if (elm) {
      elm.closeSlidingItems();
    }
    await store.dispatch("storeConsumptions/deleteRecordData", {
      recordIds: [recordId],
      app: this,
      eventBus: EventBus,
    });
  }

  public deleteAction(recordId: string) {
    EventBus.$emit("deleteListItem", this, recordId);
  }

  protected mounted() {
    this.handleControls();
    this.type = this.$route.params.type;
    if (this.$route.params.edit) {
      this.edit = true;
    } else if (this.$route.params.grapbag) {
      this.grapbag = true;
    }
  }
}
