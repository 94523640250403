
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  LivingAppsEmployeeRecord,
  LivingAppsAssignmentRecord,
  LivingAppsConsumptionRecord,
  LivingAppsRecordValue,
} from "@/types";
import store, { StoreConsumptions, StoreAssignments, StoreAuthentication, StoreLogging } from "@/store";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import { EventBus } from "../main";

@Component({
  components: {
    NoListEntriesMessage,
  },
})
export default class EmployeesList extends Vue {
  @Prop() public records!: LivingAppsEmployeeRecord[];
  @Prop() public assignmentId!: string;
  @Prop({ type: Boolean, default: true }) public userInteraction!: boolean;

  public assignment!: LivingAppsAssignmentRecord | null | undefined;

  public get costcentre(): string {
    return StoreAuthentication.getCostcentre;
  }

  public get getRecords(): LivingAppsEmployeeRecord[] {
    const realRecords = [];
    for (const record of this.records) {
      if (this.costcentre) {
        if (record.maschinenfuehrer) {
          realRecords.push(record);
        }
      } else {
        if (!record.maschinenfuehrer) {
          realRecords.push(record);
        }
      }
    }
    return realRecords;
  }

  public isAttached(employee: LivingAppsEmployeeRecord): boolean {
    if (this.assignmentId) {
      if (!this.assignment) {
        this.assignment = StoreAssignments.getRecordById(this.assignmentId);
      }
      // const assignment = StoreAssignments.getRecordById(this.assignmentId);
      const consumptions = StoreConsumptions.getRecords;
      for (const consumption of consumptions) {
        if (
          consumption.auftrag_id &&
          this.assignment &&
          this.assignment.auftrag_id &&
          employee.ma_id &&
          consumption.mitarbeiter_id &&
          !consumption.begin &&
          !consumption.ende &&
          !consumption.dauer &&
          !consumption.artikelmenge &&
          ((consumption.inaktiv2 && consumption.inaktiv2.value === false) || !consumption.inaktiv2) &&
          consumption.auftrag_id.value === this.assignment.auftrag_id.value &&
          consumption.mitarbeiter_id.value === employee.ma_id.value
        ) {
          return true;
        }
      }
    } else if (this.costcentre) {
      const cco = StoreAuthentication.getCostcentreOperator;
      if (cco && cco.id === employee.id) {
        return true;
      }
    }
    return false;
  }

  public getAssignmentNumber(): string {
    return this.assignment && this.assignment.auftrag_id ? (this.assignment.auftrag_id.value as string) : "";
  }

  public closeSlidingItems() {
    const list = document.getElementById("employees-list") as HTMLIonListElement;
    if (list) {
      list.closeSlidingItems();
    }
  }

  public async addEmployee(record: LivingAppsEmployeeRecord) {
    const assignmentNumber = this.getAssignmentNumber();
    this.closeSlidingItems();
    EventBus.$emit("addEmployee", record, assignmentNumber);
  }

  public async removeEmployee(record: LivingAppsEmployeeRecord) {
    const assignmentNumber = this.getAssignmentNumber();
    if (record.ma_id) {
      const employeeAssignmentBindingRecord = StoreConsumptions.getEmployeeAssignmentBinding(
        assignmentNumber,
        record.ma_id.value
      ) as LivingAppsConsumptionRecord | null;
      if (employeeAssignmentBindingRecord) {
        await store.dispatch("storeConsumptions/deleteRecordData", {
          recordIds: [employeeAssignmentBindingRecord.id],
          app: this,
          eventBus: EventBus,
        });
        this.closeSlidingItems();
      }
    }
  }

  public async inactivateEmployee(record: LivingAppsEmployeeRecord) {
    const lc = await this.$ionic.loadingController.create({
      message:
        "Mitarbeiter wird vom Auftrag entfernt. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!",
    });
    lc.present();
    try {
      const assignmentNumber = this.getAssignmentNumber();
      if (record.ma_id) {
        this.closeSlidingItems();
        const employeeAssignmentBindingRecord = StoreConsumptions.getEmployeeAssignmentBinding(
          assignmentNumber,
          record.ma_id.value
        ) as LivingAppsConsumptionRecord | null;
        if (employeeAssignmentBindingRecord) {
          const data: LivingAppsConsumptionRecord = {};
          data.id = employeeAssignmentBindingRecord.id;
          data.inaktiv2 = { value: true };
          await store.dispatch("storeConsumptions/updateRecordData", {
            mutation: saveConsumptionsRecordMutation,
            data,
            app: this,
            eventBus: EventBus,
          });
        }
      }
      lc.dismiss();
    } catch (error) {
      const data: any = {
        title: `Inactivate Employee (${navigator.onLine ? "Online" : "offline"})`,
        error,
      };
      StoreLogging.push(data);
    }
  }

  public async changeCostcentreOperator(record: LivingAppsEmployeeRecord) {
    if (this.costcentre) {
      StoreAuthentication.setCostcentreOperator({
        employee: record,
        newToken: true,
      });
      EventBus.$emit("dismissEmployeeModal");
    }
  }
}
