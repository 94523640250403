
import { ClipboardSortedTypes, LivingAppsClipboardRecord } from "@/clipboardTypes";
import { Component, Prop, Mixins } from "vue-property-decorator";
import AssignmentsList from "@/components/AssignmentsList.vue";
import {
  ClipboardReportSelectionSet,
  LivingAppsAssignmentRecord,
  LivingAppsFileControl,
  LivingAppsLookupItem,
  LivingAppsRecordValue,
} from "@/types";
import store, { StoreAssignments } from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import { EventBus } from "@/main";
import { saveReportsRecordMutation } from "@/store/graphql/SaveReportsRecord.graphql";
import { LoadingOptions } from "@ionic/core";

@Component({
  components: {
    AssignmentsList,
  },
})
export default class GenerateReportAssiSelection extends Mixins(GlobalUser, Utils, ClipboardUtils) {
  @Prop(Object) public sortedSelections!: ClipboardSortedTypes;
  @Prop({ type: Boolean, default: false }) public multiReports!: boolean;
  @Prop(Function) public dismissModal!: any;
  @Prop(Object) public assiRecord!: LivingAppsAssignmentRecord | null;

  public currSelection: string = "";
  public currSortedSelections = {
    note: [...this.sortedSelections.note],
    image: [...this.sortedSelections.image],
    audio: [...this.sortedSelections.audio],
  };

  private lineDecorator = "\n---\n";

  public get maintenanceRecords() {
    return this.getRecords("wartung");
  }

  public get repairsRecords() {
    return this.getRecords("reparatur");
  }

  public async selectionSetForReport(collectNotes?: boolean): Promise<ClipboardReportSelectionSet | null> {
    const selectionSet: ClipboardReportSelectionSet = { note: "", image: null, audio: null };
    if (this.currSortedSelections.note.length) {
      while (this.currSortedSelections.note.length) {
        selectionSet.note += `${this.currSortedSelections.note.shift()?.notiz?.value}${this.lineDecorator}`;
      }
    }
    if (this.currSortedSelections.image.length) {
      if (collectNotes) {
        for (const imageRecordForNote of this.currSortedSelections.image) {
          if (imageRecordForNote?.notiz) {
            selectionSet.note += `${imageRecordForNote?.notiz?.value}${this.lineDecorator}`;
          }
        }
      }
      const imageRecord = this.currSortedSelections.image.shift() as LivingAppsClipboardRecord;
      const image = await this.clipboardItemAsFile(imageRecord);
      selectionSet.image = image;
    }
    if (this.currSortedSelections.audio.length) {
      if (collectNotes) {
        for (const audioRecordForNote of this.currSortedSelections.audio) {
          if (audioRecordForNote?.notiz) {
            selectionSet.note += `${audioRecordForNote?.notiz?.value}${this.lineDecorator}`;
          }
        }
      }
      const audioRecord = this.currSortedSelections.audio.shift() as LivingAppsClipboardRecord;
      const audio = await this.clipboardItemAsFile(audioRecord);
      selectionSet.audio = audio;
    }
    if (selectionSet.note || selectionSet.image || selectionSet.audio) {
      return selectionSet;
    }
    return null;
  }

  public getRecords(type: string) {
    const realRecords: LivingAppsAssignmentRecord[] = [];
    const records: LivingAppsAssignmentRecord[] = StoreAssignments.getRecords;
    for (const record of records) {
      const status: string = record.status ? (record.status.value as LivingAppsLookupItem[])[0].key : "";
      if (type === "archive" && status === "erledigt") {
        realRecords.push(record);
      } else if (
        record.typ &&
        record.typ.value[0].key === type &&
        record.mitarbeiter &&
        !record.maschinenfuehrer &&
        status !== "erledigt"
      ) {
        realRecords.push(record);
      }
    }
    return realRecords;
  }

  public handleSelection(e: CustomEvent) {
    this.currSelection = e.detail.value;
  }

  public laRecordWrapper(
    value: string | LivingAppsFileControl | LivingAppsLookupItem[] | File | null
  ): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async confirmSelection() {
    const data: LivingAppsAssignmentRecord | any = {};
    const userSymbol = this.getUserSymbol();
    const assiRecord =
      this.assiRecord || (StoreAssignments.getRecordById(this.currSelection) as LivingAppsAssignmentRecord);
    let collectNotes = true;
    let reportCount = 0;
    data.auftrag_id = this.laRecordWrapper(assiRecord.auftrag_id?.value as string);
    data.offline_id = this.laRecordWrapper(null);
    data.anl_id = this.laRecordWrapper(assiRecord.anl_id?.value as string);
    data.typ = this.laRecordWrapper([{ key: "bericht", label: "bericht" }]);
    data.mitarbeiter = this.laRecordWrapper(userSymbol);
    data.angelegt_von = this.laRecordWrapper(userSymbol);
    data.angelegt_am = this.laRecordWrapper(this.getISOWithTimezoneString());
    if (this.sortedSelections.image.length > 1 || this.sortedSelections.audio.length > 1) {
      reportCount++;
    }
    const lcOpts: LoadingOptions = {
      message: `Erzeuge Bericht${this.multiReports ? "e" : ""}${
        assiRecord.titel?.value ? ` für Auftrag: ${assiRecord.titel?.value}` : ""
      }`,
    };
    const lc = await this.$ionic.loadingController.create(lcOpts);
    lc.present();
    try {
      let selectionSet = await this.selectionSetForReport(collectNotes);
      while (selectionSet) {
        let title = reportCount ? `Aut. Bericht, Teil ${reportCount}` : `Aut. Bericht`;
        if (selectionSet.note) {
          data.beschreibung = this.laRecordWrapper(selectionSet.note);
        }
        if (data.beschreibung) {
          const desc = data.beschreibung.value;
          const ldRegexp = new RegExp(this.lineDecorator);
          const matchFound: RegExpMatchArray | null = desc.match(ldRegexp);
          if (matchFound && matchFound.length) {
            title += `, ${desc.substring(0, matchFound.index as number)}...`;
          } else {
            title += `, ${desc}.`;
          }
        }
        data.titel = this.laRecordWrapper(title);
        data.upload2 = null;
        data.audio = null;
        if (selectionSet.image) {
          data.upload2 = this.laRecordWrapper(selectionSet.image);
        }
        if (selectionSet.audio) {
          data.audio = this.laRecordWrapper(selectionSet.audio);
        }
        await store.dispatch("storeAssignments/createRecordData", {
          mutation: saveReportsRecordMutation,
          data,
          app: this,
          eventBus: EventBus,
        });
        collectNotes = false;
        if (reportCount) {
          reportCount++;
        }
        selectionSet = await this.selectionSetForReport(collectNotes);
      }
      await store.dispatch("storeClipboard/cleanupWithSortedSelection", this.sortedSelections);
      lc.dismiss();
      setTimeout(() => {
        this.dismissModal();
      }, 1000);
    } catch (error) {
      lc.dismiss();
      this.dismissModal();
      throw new Error(`Generate reports error:${(error as unknown as any).message}`);
    }
  }

  public cancel() {
    this.dismissModal();
  }
}
