
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl,
  LivingAppsRecordValue,
  LivingAppsFileControl,
  LivingAppsLookupItem,
} from "@/types";
import { LivingAppsClipboardRecord } from "@/clipboardTypes";
import store, { HOST_URL, StoreAuthentication, StoreClipboard } from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import AudioRecordingInput from "@/components/AudioRecordingInput.vue";
import ClipboardFormFieldIcon from "@/components/ClipboardFormFieldIcon.vue";
import { saveReportsRecordMutation } from "@/store/graphql/SaveReportsRecord.graphql";
import { AlertButton, AlertOptions } from "@ionic/core";

@Component({
  components: {
    AudioRecordingInput,
    ClipboardFormFieldIcon,
  },
})
export default class ReportsForm extends Mixins(GlobalUser, Utils, ClipboardUtils) {
  [index: string]: any;
  @Prop() public assignmentRecord?: LivingAppsAssignmentRecord;
  @Prop() public record?: LivingAppsAssignmentRecord;
  @Prop() public controls?: LivingAppsAssignmentControl | null;
  @Prop() public type!: string;

  public formTitel: string = "";
  public formBeschreibung: string = "";
  public formUpload2: File | LivingAppsFileControl | null = null;
  public formUpload2Preview: string = "";
  public formAudio: File | LivingAppsFileControl | null = null;

  public titelFromClipboard: boolean = false;
  public beschreibungFromClipboard: boolean = false;
  public upload2FromClipboard: boolean = false;
  public audioFromClipboard: boolean = false;

  public isDirty: boolean = false;

  public mounted() {
    EventBus.$off("clipboardSelection").$on("clipboardSelection", this.handleClipboardSelection);
    this.formTitel = this.getFieldValue("titel");
    this.formBeschreibung = this.getFieldValue("beschreibung");
    this.formUpload2 = this.getFieldValue("upload2");
    this.formAudio = this.getFieldValue("audio");
    this.titelFromClipboard = false;
    this.beschreibungFromClipboard = false;
    this.upload2FromClipboard = false;
    this.audioFromClipboard = false;
  }

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public get titel(): string {
    return this.formTitel;
  }

  public set titel(value: string) {
    this.formTitel = value;
    this.titelFromClipboard = false;
    this.isDirty = true;
  }

  public get beschreibung(): string {
    return this.formBeschreibung;
  }

  public set beschreibung(value: string) {
    this.formBeschreibung = value;
    this.beschreibungFromClipboard = false;
    this.isDirty = true;
  }

  public get upload2(): File | LivingAppsFileControl | null {
    return this.formUpload2;
  }

  public set upload2(value: File | LivingAppsFileControl | null) {
    this.formUpload2 = value;
    this.upload2FromClipboard = false;
    this.isDirty = true;
  }

  public get audio(): File | LivingAppsFileControl | null {
    return this.formAudio;
  }

  public set audio(value: File | LivingAppsFileControl | null) {
    this.formAudio = value;
    this.audioFromClipboard = false;
    this.isDirty = true;
  }

  public getControl(name: string): any | null {
    if (this.controls && name in this.controls) {
      return this.controls[name].control;
    }
    return null;
  }

  public deleteFile(name: string) {
    this[name] = null;
    this.isDirty = true;
  }

  public handleRecordStarted() {
    this.$emit("recordStarted");
  }

  public handleRecordedAudio(file: File) {
    this.audio = file;
    this.$emit("recordCompleted");
  }

  public deleteAudio() {
    this.audio = null;
  }

  public getIconForMimetype(mimetype: string): string {
    if (mimetype.includes("/pdf")) {
      return require("@/assets/pdf-icon.png");
    } else if (mimetype.includes("/msword") || mimetype.includes(".wordprocessingml.document")) {
      return require("@/assets/doc-icon.png");
    } else if (mimetype.includes("/vnd.ms-excel") || mimetype.includes(".spreadsheetml.sheet")) {
      return require("@/assets/xls-icon.png");
    }
    return require("@/assets/unknown-icon.png");
  }

  public updatePreview(fileObj: File | LivingAppsFileControl | null) {
    if (fileObj) {
      if (fileObj instanceof File) {
        if (fileObj.type.includes("application/")) {
          this.formUpload2Preview = this.getIconForMimetype(fileObj.type);
        } else {
          const url = URL.createObjectURL(fileObj);
          this.formUpload2Preview = url;
        }
      } else {
        if (fileObj.mimetype && fileObj.mimetype.includes("application/")) {
          this.formUpload2Preview = this.getIconForMimetype(fileObj.mimetype);
        } else {
          this.formUpload2Preview = HOST_URL + fileObj.url;
        }
      }
    }
  }

  public getFieldValue(name: string, record?: any): any {
    const control = this.getControl(name);
    const rec = record || this.record;
    if (rec && control) {
      const rep = rec[name];
      if (rep) {
        return rep.value;
      }
    }
    return null;
  }

  public async handleClipboardSelection(
    selection: LivingAppsClipboardRecord,
    fieldName: string,
    itemType: string
  ) {
    if (itemType === "note") {
      this[fieldName] = selection.notiz?.value;
    } else if (itemType === "image" || itemType === "audio") {
      const buttons: AlertButton[] = [
        {
          text: "Ignorieren",
          role: "cancel",
        },
        {
          text: "In Beschreibung einfügen",
          handler: () => {
            const desc = this.beschreibung;
            if (desc) {
              const newDesc = `${desc}; ${selection.notiz?.value}`;
              this.beschreibung = newDesc;
              return;
            }
            this.beschreibung = selection.notiz?.value as string;
          },
        },
        ...(() => {
          if (this.beschreibung) {
            return [
              {
                text: "Beschreibung damit ersetzen",
                handler: () => {
                  this.beschreibung = selection.notiz?.value as string;
                },
              },
            ];
          }
          return [];
        })(),
      ];
      let opts: AlertOptions;
      if (itemType === "image") {
        const image = await this.clipboardItemAsFile(selection);
        this[fieldName] = image;
        opts = {
          header: "Einfügen aus Pinnwand.",
          subHeader: "Bild mit Notiz.",
          message: "Zu diesem Bild wurde eine Notiz hinterlegt, wie möchten sie die Notiz verwenden?",
          buttons,
        };
      } else {
        const audio = await this.clipboardItemAsFile(selection);
        this[fieldName] = audio;
        opts = {
          header: "Einfügen aus Pinnwand.",
          subHeader: "Audiodatei mit Notiz.",
          message: "Zu dieser Audiodatei wurde eine Notiz hinterlegt, wie möchten sie die Notiz verwenden?",
          buttons,
        };
      }
      if (selection.notiz) {
        this.$ionic.alertController.create(opts).then((a) => a.present());
      }
    }
    this[`${fieldName}FromClipboard`] = true;
  }

  public laRecordWrapper(
    value: string | LivingAppsFileControl | LivingAppsLookupItem[] | File | null
  ): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async save() {
    const data: LivingAppsAssignmentRecord | any = {};
    const userSymbol = this.getUserSymbol();
    data.auftrag_id = this.laRecordWrapper(this.getFieldValue("auftrag_id", this.assignmentRecord));
    data.offline_id = this.laRecordWrapper(this.getFieldValue("offline_id", this.record));
    data.mitarbeiter = this.laRecordWrapper(this.getFieldValue("mitarbeiter", this.assignmentRecord));
    data.anl_id = this.laRecordWrapper(this.getFieldValue("anl_id", this.assignmentRecord));
    data.angelegt_am = this.laRecordWrapper(this.getFieldValue("angelegt_am"));
    data.angelegt_von = this.laRecordWrapper(this.getFieldValue("angelegt_von"));
    data.titel = this.laRecordWrapper(this.formTitel);
    data.beschreibung = this.laRecordWrapper(this.formBeschreibung);
    data.upload2 = this.laRecordWrapper(this.formUpload2);
    data.audio = this.laRecordWrapper(this.formAudio);
    if (this.record) {
      data.id = this.record.id;
      await store.dispatch("storeAssignments/updateRecordData", {
        mutation: saveReportsRecordMutation,
        data,
        app: this,
        eventBus: EventBus,
      });
    } else {
      data.typ = this.laRecordWrapper([{ key: "bericht", label: "bericht" }]);
      const cco = StoreAuthentication.getCostcentreOperator;
      if (this.costcentre && cco && cco.kuerzel && cco.vorname && cco.nachname) {
        data.mitarbeiter = this.laRecordWrapper(cco.kuerzel.value as string);
        data.angelegt_von = this.laRecordWrapper(`${cco.vorname.value} ${cco.nachname.value}`);
      } else {
        data.mitarbeiter = this.laRecordWrapper(userSymbol);
        data.angelegt_von = this.laRecordWrapper(userSymbol);
      }
      data.angelegt_am = this.laRecordWrapper(this.getISOWithTimezoneString());
      await store.dispatch("storeAssignments/createRecordData", {
        mutation: saveReportsRecordMutation,
        data,
        app: this,
        eventBus: EventBus,
      });
    }
  }

  // public showClipboard(itemType: string) {
  //   const useRadio = true;
  //   EventBus.$emit("showClipboard", false, this.assignmentRecord, this.record, itemType, useRadio);
  // }

  @Watch("upload2")
  protected onImageChange(
    value: File | LivingAppsFileControl | null,
    oldValue: File | LivingAppsFileControl | null
  ) {
    this.updatePreview(value);
  }

  @Watch("titelFromClipboard")
  protected onTitelChange(value: boolean, oldValue: boolean) {
    if (!value) {
      StoreClipboard.setAssignedQueueForItem({ itemType: "note", fieldName: "titel" });
    }
  }

  @Watch("beschreibungFromClipboard")
  protected onBeschreibungChange(value: boolean, oldValue: boolean) {
    if (!value) {
      StoreClipboard.setAssignedQueueForItem({ itemType: "note", fieldName: "beschreibung" });
    }
  }

  @Watch("upload2FromClipboard")
  protected onUpload2Change(value: boolean, oldValue: boolean) {
    if (!value) {
      StoreClipboard.setAssignedQueueForItem({ itemType: "image", fieldName: "upload2" });
    }
  }

  @Watch("audioFromClipboard")
  protected onAudioChange(value: boolean, oldValue: boolean) {
    if (!value) {
      StoreClipboard.setAssignedQueueForItem({ itemType: "audio", fieldName: "audio" });
    }
  }
}
