
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import { Component, Mixins } from "vue-property-decorator";
import AssignmentsList from "@/components/AssignmentsList.vue";
import CcAssignmentAdviceStatusList from "@/components/CcAssignmentsAdviceStatusList.vue";
import CcmaintenanceList from "@/components/CcmaintenanceList.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import ClipboardButton from "@/components/ClipboardButton.vue";
import {
  LivingAppsAssignmentRecord,
  LivingAppsConsumptionRecord,
  StandardAlertData,
  LivingAppsLookupItem,
} from "@/types";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import store, {
  StoreAssignments,
  StoreAuthentication,
  StoreConsumptions,
  StoreCcmaintenance,
  StoreAssignmentsAdviceStatus,
} from "../store";
import { EventBus } from "@/main";

@Component({
  components: {
    AssignmentsList,
    CcAssignmentAdviceStatusList,
    CcmaintenanceList,
    ActiveAssignmentButton,
    ClipboardButton,
  },
})
export default class Main extends Mixins(GlobalUser, Utils) {
  public refreshText: string = "Bitte warten sie...";

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public get maintenanceRecords() {
    if (this.costcentre) {
      return StoreCcmaintenance.getRecords;
    }
    return this.getRecords("wartung");
  }

  public get assignmentAdviceStatusRecords() {
    if (this.costcentre) {
      return StoreAssignmentsAdviceStatus.getRecords || [];
    }
    return [];
  }

  public get repairsRecords() {
    return this.getRecords("reparatur");
  }

  public get archiveRecords() {
    return this.getRecords("archive");
  }

  public get userSymbol() {
    const symbol = this.getUserSymbol();
    if (symbol) {
      return symbol.toUpperCase();
    }
    return "";
  }

  public get userFullname() {
    if (this.costcentre) {
      const cco = StoreAuthentication.getCostcentreOperator;
      if (cco && cco.vorname && cco.nachname) {
        return `${cco.vorname.value} ${cco.nachname.value}`;
      } else {
        EventBus.$emit("changeCostcentreEmployee");
      }
    }
    return "Maschinenführer wählen!";
  }

  public get versionInfo() {
    return `(v-${store.state.getters.appVersion(store.state)})`;
  }

  public getRecords(type: string) {
    const realRecords: LivingAppsAssignmentRecord[] = [];
    const records: LivingAppsAssignmentRecord[] = StoreAssignments.getRecords;
    if (this.costcentre) {
      for (const record of records) {
        if (
          record.typ &&
          record.typ.value[0].key === type &&
          record.anl_id &&
          record.anl_id.value === this.costcentre &&
          record.maschinenfuehrer &&
          status !== "erledigt"
        ) {
          realRecords.push(record);
        }
      }
    } else {
      for (const record of records) {
        const status: string = record.status ? (record.status.value as LivingAppsLookupItem[])[0].key : "";
        if (type === "archive" && status === "erledigt") {
          realRecords.push(record);
        } else if (
          record.typ &&
          record.typ.value[0].key === type &&
          record.mitarbeiter &&
          !record.maschinenfuehrer &&
          status !== "erledigt"
        ) {
          realRecords.push(record);
        }
      }
    }
    return realRecords;
  }

  public count(storeToUse: any, type: string) {
    let len: number = 0;
    if (type) {
      for (const record of storeToUse.getRecords) {
        if (record.typ.value[0].key === type) {
          len++;
        }
      }
    } else {
      return storeToUse.recordsLength;
    }
    return len;
  }

  public handleClick(route: string) {
    this.$router.push(route);
  }

  public handleNewAssignmentClick(e: Event) {
    const adhoc: LivingAppsAssignmentRecord | null = StoreAssignments.getAdhoc(this.getUserSymbol());
    if (adhoc) {
      EventBus.$emit("newAssignment", this.costcentre);
    } else {
      const stdErrData: StandardAlertData = {
        header: "Kein neuer Auftrag möglich!",
        subHeader: "Anzahl der lokalen neuen Aufträge erschöpft.",
        message: "Führen sie auf der Startseite einen Refresh durch, um neue Aufträge zu laden.",
      };
      EventBus.$emit("standardError", stdErrData);
    }
  }

  public refresh(event: Event) {
    EventBus.$emit("refreshAll", {
      reload: true,
      app: this,
      event,
    });
  }

  public get hasActiveAssignment(): boolean {
    const record = StoreAssignments.getScannedRecord;
    if (record) {
      return true;
    }
    return false;
  }

  public scanAssignmentTarget() {
    EventBus.$emit("scanner", "machine-scan", null, "Auftrag scannen", "Starten");
  }

  public scanCostCentreTarget() {
    EventBus.$emit("scanner", "cost-centre-scan", null, "Kostenstelle scannen", "Speichern");
  }

  public assignmentStart() {
    // todo
  }

  public activateAssignment() {
    this.$router.push({ name: "active_assignment" });
  }

  public checkForAssignmentStart(emitter: any, record: LivingAppsAssignmentRecord) {
    const recordId = record.id;
    if (StoreAssignments.getScannedRecord && StoreAssignments.getScannedRecord.id !== recordId) {
      const data: StandardAlertData = {
        header: "Laufender Auftrag!",
        subHeader: "Es ist noch ein Auftrag aktiv.",
        message: `So lange ein Auftrag aktiv ist kann kein weiterer Auftrag
        gestartet werden. Pausieren oder beenden Sie den aktiven Auftrag um einen
        anderen Auftrag starten zu können.`,
      };
      EventBus.$emit("standardError", data);
    } else {
      this.startAssignment(record);
    }
    if (typeof emitter.scanCancel === "function") {
      emitter.scanCancel();
    }
    if (emitter.identifier) {
      const elm = document.getElementById(emitter.identifier) as HTMLIonListElement;
      if (elm) {
        elm.closeSlidingItems();
      }
    }
  }

  public async startAssignment(record: LivingAppsAssignmentRecord) {
    const lc = await this.$ionic.loadingController.create({
      message:
        "Auftrag wird gestartet. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!",
    });
    lc.present();

    const consumptionMutation = saveConsumptionsRecordMutation;
    const assignmentRecord = StoreAssignments.getRecordById(record.id);
    const assignmentId = assignmentRecord.auftrag_id ? (assignmentRecord.auftrag_id.value as string) : "";
    const assignmentData = { ...assignmentRecord };

    const startRecord = StoreConsumptions.getOpenTimeConsumptionForAssignment(
      assignmentId,
      this.getUserId() as string
    ) as LivingAppsConsumptionRecord | null;
    // If we have a started assignment, we have to pause it. Otherwise we
    // create a new consumption entry with a new start.
    if (startRecord) {
      EventBus.$emit("newStatus", startRecord);
    } else {
      const consumptionData: LivingAppsConsumptionRecord = {};
      consumptionData.auftrag_id = assignmentRecord.auftrag_id;
      consumptionData.auftrag_bezeichnung = assignmentRecord.auftrag_bezeichnung;
      const cco = StoreAuthentication.getCostcentreOperator;
      if (cco) {
        if (cco.ma_id) {
          consumptionData.mitarbeiter_id = { value: cco.ma_id.value };
        }
        if (cco.vorname && cco.nachname) {
          consumptionData.mitarbeitername = {
            value: `${cco.vorname.value} ${cco.nachname.value}`,
          };
        }
      } else {
        consumptionData.mitarbeiter_id = { value: this.getUserId() };
        consumptionData.mitarbeitername = { value: this.getUserFullname() };
      }
      consumptionData.begin = { value: this.getISOWithTimezoneString() };
      await store.dispatch("storeConsumptions/createRecordData", {
        mutation: consumptionMutation,
        data: consumptionData,
        app: this,
        eventBus: EventBus,
      });
      StoreAssignments.setScannedRecordData(assignmentRecord);
      this.activateAssignment();
    }
    lc.dismiss();
  }

  public async statusForAssignment(status: string, consumptionRecord: LivingAppsConsumptionRecord) {
    const consumptionData = { ...consumptionRecord };
    consumptionData.mitarbeiterstatus = { value: status };
    consumptionData.ende = { value: this.getISOWithTimezoneString() };
    await store.dispatch("storeConsumptions/updateRecordData", {
      mutation: saveConsumptionsRecordMutation,
      data: consumptionData,
      app: this,
      eventBus: EventBus,
    });
    StoreAssignments.deleteScannedRecord();
  }

  public updateRefreshText(text: string) {
    this.refreshText = text;
  }

  public handleUser() {
    if (this.costcentre) {
      if (this.hasActiveAssignment) {
        const alertData: StandardAlertData = {
          header: "Laufender Auftrag!",
          subHeader: "Maschinenführer kann nicht gewechselt werden, da ein Auftrag läuft.",
          message:
            "Setzen Sie einen Auftragsstatus, danach können Sie auf den neuen Maschinenführer wechseln.",
        };
        EventBus.$emit("standardError", alertData);
      } else {
        EventBus.$emit("changeCostcentreEmployee");
      }
    } else {
      EventBus.$emit("requestInfo");
    }
  }

  protected mounted() {
    EventBus.$off("startAssignment").$on("startAssignment", this.checkForAssignmentStart);
    EventBus.$off("updateRefreshText").$on("updateRefreshText", this.updateRefreshText);
  }
}
