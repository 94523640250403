
import { Component, Vue, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import store, {
  DB_NAME,
  HOST_URL,
  StoreAuthentication,
  StoreAssignments,
  StoreConsumptions,
  StoreLogging,
  StoreClipboard,
} from "./store";
import { AlertController } from "@ionic/vue/dist/types/controllers";
import { OverlayController, AlertOptions, LoadingOptions } from "@ionic/core";
import LoginForm from "@/components/LoginForm.vue";
import Clipboard from "@/components/ClipboardModal.vue";
import ScanQrCodeElement from "@/components/ScanQrCodeElement.vue";
import AmountCard from "@/components/AmountCard.vue";
import GenerateReportAssiSelection from "@/components/GenerateReportAssiSelection.vue";
import StatusForm from "@/components/StatusForm.vue";
import AddEmployeeForm from "@/components/AddEmployeeForm.vue";
import AdhocForm from "@/components/AdhocForm.vue";
import Employees from "@/views/Employees.vue";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import { saveRecordMutation } from "@/store/graphql/SaveAssignmentRecord.graphql";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";

import {
  StandardAlertData,
  LivingAppsAssignmentRecord,
  LivingAppsConsumptionRecord,
  LivingAppsEmployeeRecord,
  LivingAppsFileControl,
} from "@/types";
import localforage from "localforage";
import { ClipboardSortedTypes, LivingAppsClipboardRecord } from "./clipboardTypes";

@Component
export default class App extends Mixins(GlobalUser, Utils) {
  public onlineCheckerIntervalID: number | null = null;
  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public initOnlineChecker() {
    this.onlineCheckerIntervalID = window.setInterval(() => {
      if (navigator.onLine) {
        if (this.onlineCheckerIntervalID) {
          window.clearInterval(this.onlineCheckerIntervalID);
          this.onlineCheckerIntervalID = null;
        }
      }
    }, 50000);
  }

  public showLoginPane() {
    return this.$ionic.modalController
      .create({
        id: "loginPane",
        component: LoginForm,
        componentProps: {
          parent: this,
          propsData: {
            dismissModal: () => {
              this.refreshAll({
                reload: true,
                app: this,
              });
              this.$ionic.modalController.dismiss(null, "login", "loginPane");
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public showScannerModal(mode: string, postMode: string, title: string, buttonTitle: string) {
    return this.$ionic.modalController
      .create({
        id: "scanner-component",
        component: ScanQrCodeElement,
        componentProps: {
          parent: this,
          propsData: {
            mode,
            postMode,
            title,
            buttonTitle,
            dismissModal: (id: string) => {
              this.$ionic.modalController.dismiss(null, "", id);
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public showAmountModal(record: LivingAppsConsumptionRecord, title: string, subtitle: string, desc: string) {
    return this.$ionic.modalController
      .create({
        cssClass: ["content-transparent"],
        component: AmountCard,
        componentProps: {
          parent: this,
          propsData: {
            record,
            title,
            subtitle,
            desc,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public generateReportsAssiSelect(
    sortedSelections: ClipboardSortedTypes,
    multiReports: boolean,
    assiRecord: LivingAppsAssignmentRecord | null = null
  ) {
    return this.$ionic.modalController
      .create({
        cssClass: ["clipboard-assi-selection"],
        component: GenerateReportAssiSelection,
        componentProps: {
          parent: this,
          propsData: {
            sortedSelections,
            multiReports,
            assiRecord,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public dirtyFormAlert(viewVue: any, formVue: any): any {
    const opts: AlertOptions = {
      header: "Daten Speichern",
      message: "Möchten Sie die Daten speichern?",
      buttons: [
        {
          text: "Nein",
          handler: () => {
            viewVue.formCancel();
          },
        },
        {
          text: "Ja",
          handler: () => {
            formVue.save();
          },
        },
      ],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public deleteListItemAlert(viewVue: any, recordId: string): any {
    const opts: AlertOptions = {
      header: "Eintrag löschen",
      subHeader: "Möchten Sie den Datensatz wirklich entfernen?",
      message: "Dies kann nicht rückgängig gemacht werden!",
      buttons: [
        {
          text: "Nein",
          handler: () => {
            viewVue.deleteCancel(recordId);
          },
        },
        {
          text: "Ja",
          handler: () => {
            viewVue.deleteItem(recordId);
          },
        },
      ],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public saveOfflineItemAlert(viewVue: any, recordId: string): any {
    let opts: AlertOptions;
    if (navigator.onLine) {
      opts = {
        header: "Eintrag senden",
        subHeader: "Die Daten werden auf dem Zielsystem gesichert.",
        message: "Momentan befindet sich der Eintrag nur offline auf ihrem Mobilgerät.",
        buttons: [
          {
            text: "Nein",
            handler: () => {
              return true;
            },
          },
          {
            text: "Ja",
            handler: () => {
              viewVue.saveOfflineItemAction(recordId);
            },
          },
        ],
      };
    } else {
      opts = {
        header: "Keine WLAN Verbindung!",
        subHeader: "Neue Einträge können nur bei stabilem WLAN ans Zielsystem gesendet werden.",
        message: "Wechseln Sie zu einem Standort mit WLAN Verbindung zum Zielsystem.",
        buttons: ["OK"],
      };
    }
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public bgSyncInfo(viewVue: any, route: any) {
    const opts: AlertOptions = {
      header: "Datenabgleich",
      subHeader: "Offline angelegte Daten wurden mit der Basis abgeglichen!",
      message: "Dies ist notwendig um die Daten synchron zu halten.",
      backdropDismiss: false,
      buttons: [
        {
          text: "Daten neu laden",
          handler: () => {
            return true;
          },
        },
      ],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public newStatusAction(record: LivingAppsConsumptionRecord) {
    return this.$ionic.modalController
      .create({
        cssClass: ["content-transparent"],
        component: StatusForm,
        componentProps: {
          parent: this,
          propsData: {
            record,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public addEmployeeAction(employeeRecord: LivingAppsEmployeeRecord, assignmentId: string) {
    return this.$ionic.modalController
      .create({
        component: AddEmployeeForm,
        componentProps: {
          parent: this,
          propsData: {
            employeeRecord,
            assignmentId,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public showChangeCostcentreEmployee() {
    return this.$ionic.modalController
      .create({
        backdropDismiss: false,
        keyboardClose: false,
        component: Employees,
        componentProps: {
          parent: this,
          propsData: {
            userInteraction: false,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public showNewAdhocAction(machineId: string) {
    return this.$ionic.modalController
      .create({
        component: AdhocForm,
        componentProps: {
          parent: this,
          propsData: {
            record: StoreAssignments.getAdhoc(this.getUserSymbol()),
            machineId,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public showNewAssignmentAction(machineId: string) {
    const cco = StoreAuthentication.getCostcentreOperator;
    let symbol = this.getUserSymbol();
    if (cco && cco.kuerzel) {
      symbol = (cco.kuerzel.value as string).toLowerCase();
    }
    return this.$ionic.modalController
      .create({
        component: AdhocForm,
        componentProps: {
          parent: this,
          propsData: {
            record: StoreAssignments.getAdhoc(symbol),
            machineId,
            newAssignment: true,
            costcentre: this.costcentre,
            dismissModal: () => {
              this.$ionic.modalController.dismiss();
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  public async refreshAll(attrs: any) {
    if (navigator.onLine) {
      EventBus.$emit("updateRefreshText", "Update Kostenstellen...");
      await store.dispatch("storeCostcentres/getDataFromEndpoint", attrs);
      EventBus.$emit("updateRefreshText", "Update Mitarbeiter...");
      await store.dispatch("storeEmployees/getDataFromEndpoint", attrs);
      EventBus.$emit("updateRefreshText", "Update Verbräuche...");
      await store.dispatch("storeConsumptions/getDataFromEndpoint", attrs);
      if (this.costcentre) {
        await store.dispatch("storeCcmaintenance/getDataFromEndpoint");
      } else {
        attrs.m_id = this.getUserId();
        EventBus.$emit("updateRefreshText", "Update Pinnwand...");
        await store.dispatch("storeClipboard/getDataFromEndpoint", attrs);
      }
      const assignmentAttrs = {
        reload: attrs.reload,
        app: attrs.app,
        event: attrs.event,
        employeeSymbol: this.getUserSymbol(),
      };
      EventBus.$emit("updateRefreshText", "Update Aufträge...");
      await store.dispatch("storeAssignments/getDataFromEndpoint", assignmentAttrs);
      if (attrs.event && attrs.event.type === "ionRefresh") {
        attrs.event.target.complete();
        EventBus.$emit("updateRefreshText", "Lade Medien, bitte warten...");
        await this.cacheDataMediaLoading(attrs.quiet);
      }
    } else {
      if (attrs.event && attrs.event.type === "ionRefresh") {
        attrs.event.target.complete();
        const stdAlertOpts: StandardAlertData = {
          header: "Gerät ist offline",
          subHeader: "Momentan besteht keine Verbindung zum Internet.",
          message: "Versuchen sie es erneut, wenn das Gerät wieder online ist.",
        };
        this.showStandardAlert(stdAlertOpts);
      }
    }
  }

  public async cacheDataMedia(loadingController?: HTMLIonLoadingElement) {
    const assignments: LivingAppsAssignmentRecord[] | null = await localforage.getItem(
      StoreAssignments.IDB_KEY_RECORDS
    );
    const consumptions: LivingAppsConsumptionRecord[] | null = await localforage.getItem(
      StoreConsumptions.IDB_KEY_RECORDS
    );
    const clipboardItems: LivingAppsClipboardRecord[] | null = await localforage.getItem(
      StoreClipboard.IDB_KEY_RECORDS
    );
    let interval: number | null;
    let fetchCounts = 0;
    try {
      const cache: Cache = await caches.open(DB_NAME + "-media");
      if (assignments) {
        for (const assignment of assignments) {
          if (
            (assignment.upload2 && !(assignment.upload2.value instanceof File)) ||
            (assignment.audio && !(assignment.audio.value instanceof File))
          ) {
            if (assignment.upload2) {
              const url: RequestInfo = assignment.upload2.value
                ? ((assignment.upload2.value as LivingAppsFileControl).url as string)
                : "";
              fetchCounts++;
              const req: Request = new Request(HOST_URL + url);
              await cache.add(req);
              fetchCounts--;
            }
            if (assignment.audio) {
              const url: RequestInfo = assignment.audio.value
                ? ((assignment.audio.value as LivingAppsFileControl).url as string)
                : "";
              fetchCounts++;
              const req: Request = new Request(HOST_URL + url);
              await cache.add(req);
              fetchCounts--;
            }
          }
        }
      }
      if (consumptions) {
        for (const consumption of consumptions) {
          if (consumption.audio && !(consumption.audio.value instanceof File)) {
            const url: RequestInfo = consumption.audio.value
              ? ((consumption.audio.value as LivingAppsFileControl).url as string)
              : "";
            fetchCounts++;
            const req: Request = new Request(HOST_URL + url);
            await cache.add(req);
            fetchCounts--;
          }
        }
      }
      if (clipboardItems) {
        for (const item of clipboardItems) {
          if (item.bild && !(item.bild.value instanceof File)) {
            const url: RequestInfo = item.bild.value
              ? ((item.bild.value as LivingAppsFileControl).url as string)
              : "";
            fetchCounts++;
            const req: Request = new Request(HOST_URL + url);
            await cache.add(req);
            fetchCounts--;
          }
          if (item.sprachnachricht && !(item.sprachnachricht.value instanceof File)) {
            const url: RequestInfo = item.sprachnachricht.value
              ? ((item.sprachnachricht.value as LivingAppsFileControl).url as string)
              : "";
            fetchCounts++;
            const req: Request = new Request(HOST_URL + url);
            await cache.add(req);
            fetchCounts--;
          }
        }
      }
    } catch (error) {
      const data: any = {
        title: `Cache media (${navigator.onLine ? "Online" : "Offline"})`,
        error,
      };
      StoreLogging.push(data);
      fetchCounts = 0;
    }
    interval = window.setInterval(() => {
      if (fetchCounts <= 0) {
        if (interval) {
          window.clearInterval(interval);
          interval = null;
        }
        if (loadingController) {
          loadingController.dismiss();
        }
      }
    }, 1000);
  }

  public async cacheDataMediaLoading(quiet: boolean | undefined) {
    const lcOpts: LoadingOptions = {
      message: "Lade Mediadaten für Offlinebetrieb.",
    };
    const lc = await this.$ionic.loadingController.create(lcOpts);
    this.cacheDataMedia(lc);
    lc.present();
  }

  public showStandardAlert(data: StandardAlertData) {
    let opts: AlertOptions;
    opts = {
      ...data,
      buttons: ["OK"],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public showInfoAlert() {
    let opts: AlertOptions;
    const fullname = this.getUserFullname();
    const emailObj = (this.getUser() as LivingAppsEmployeeRecord).e_mail;
    const email = emailObj ? emailObj.value : "";
    opts = {
      header: "Angemeldeter Nutzer",
      message: `${fullname}<br/>${email}`,
      buttons: [
        {
          text: "LOG",
          handler: () => {
            this.$router.push("logging");
          },
        },
        "OK",
      ],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public showAssignmentNotFoundAlert(machineId: string) {
    let opts: AlertOptions;
    opts = {
      header: "Unbekannte Kostenstelle!",
      subHeader: "Diese Kostenstelle konnte keinem Auftrag zugeordnet werden.",
      message: "Möchten Sie stattdessen einen Adhoc-Auftrag erstellen?",
      buttons: [
        {
          text: "Nein",
          role: "cancel",
          handler: () => {
            return false;
          },
        },
        {
          text: "Adhoc erstellen",
          handler: () => {
            EventBus.$emit("newAdhoc", machineId);
          },
        },
      ],
    };
    return this.$ionic.alertController.create(opts).then((a) => a.present());
  }

  public async finishAssignment() {
    const activeRecord = StoreAssignments.getScannedRecord;
    const recordId = activeRecord ? activeRecord.id : null;
    if (activeRecord) {
      const data: LivingAppsAssignmentRecord = { ...activeRecord };
      const mutation: any = saveRecordMutation;
      const assignmentId = activeRecord.auftrag_id ? (activeRecord.auftrag_id.value as string) : "";
      data.status = { value: [{ key: "erledigt", label: "Erledigt" }] };
      await store.dispatch("storeAssignments/updateRecordData", {
        mutation,
        data,
        app: this,
        eventBus: EventBus,
      });
      const openTimeConsumption: LivingAppsConsumptionRecord | null =
        StoreConsumptions.getOpenTimeConsumptionForAssignment(assignmentId, this.getUserId() as string);
      if (openTimeConsumption) {
        openTimeConsumption.mitarbeiterstatus = { value: "Abgeschlossen" };
        openTimeConsumption.ende = { value: this.getISOWithTimezoneString() };
        await store.dispatch("storeConsumptions/updateRecordData", {
          mutation: saveConsumptionsRecordMutation,
          data: openTimeConsumption,
          app: this,
          eventBus: EventBus,
        });
      }
      await StoreAssignments.deleteScannedRecord();
      if (this.$route.name !== "main") {
        this.$router.push("main");
      }
    }
  }

  public async showClipboard(
    insertFirst: boolean,
    assiRecord?: LivingAppsAssignmentRecord,
    reportRecord?: LivingAppsAssignmentRecord,
    itemType?: string,
    useRadio?: boolean,
    segmentStart?: string,
    fieldName?: string
  ) {
    return this.$ionic.modalController
      .create({
        id: "clipboard",
        component: Clipboard,
        componentProps: {
          parent: this,
          propsData: {
            insertFirst,
            assiRecord,
            reportRecord,
            itemType,
            useRadio,
            segmentStart,
            fieldName,
            dismissModal: () => {
              this.$ionic.modalController.dismiss(null, "cancel", "clipboard");
            },
          },
        },
      })
      .then((m) => {
        m.present();
      });
  }

  protected mounted() {
    EventBus.$on("offline", this.initOnlineChecker);
    EventBus.$on("standardError", this.showStandardAlert);
    EventBus.$on("authError", this.showLoginPane);
    EventBus.$on("standardAmount", this.showAmountModal);
    EventBus.$on("scanner", this.showScannerModal);
    EventBus.$on("dirtyForm", this.dirtyFormAlert);
    // EventBus.$on("finishAssignment", this.finishAssignmentAlert);
    EventBus.$on("deleteListItem", this.deleteListItemAlert);
    EventBus.$on("saveOfflineItem", this.saveOfflineItemAlert);
    EventBus.$on("refreshAll", this.refreshAll);
    EventBus.$on("bgsync", this.bgSyncInfo);
    EventBus.$on("newStatus", this.newStatusAction);
    EventBus.$on("addEmployee", this.addEmployeeAction);
    EventBus.$on("assignmentNotFound", this.showAssignmentNotFoundAlert);
    EventBus.$on("newAdhoc", this.showNewAdhocAction);
    EventBus.$on("newAssignment", this.showNewAssignmentAction);
    EventBus.$on("requestInfo", this.showInfoAlert);
    EventBus.$on("changeCostcentreEmployee", this.showChangeCostcentreEmployee);
    EventBus.$on("showClipboard", this.showClipboard);
    EventBus.$on("generateReportsAssiSelect", this.generateReportsAssiSelect);
  }

  protected created() {
    this.$nextTick(() => {
      StoreAuthentication.currentAuthToken().then((authTokens) => {
        if (!authTokens || !(authTokens.authToken || authTokens.graphqlAuthToken)) {
          this.showLoginPane();
        } else {
          StoreAuthentication.currentCostcentre().then(() => {
            this.refreshAll({
              reload: true,
              app: this,
            });
          });
        }
      });
    });
  }
}
