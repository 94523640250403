
import { LivingAppsAssignmentRecord, LivingAppsFileControl } from "@/types";
import {
  ClipboardAssignmentList,
  ClipboardAssignmentObject,
  LivingAppsClipboardRecord,
} from "@/clipboardTypes";
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import AudioRecordingInput from "@/components/AudioRecordingInput.vue";
import Utils from "@/mixins/Utils.vue";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import { HOST_URL, StoreAssignments, StoreClipboard } from "@/store";
import { EventBus } from "@/main";

@Component({
  components: {
    AudioRecordingInput,
  },
})
export default class ClipboardSegmentItems extends Mixins(Utils, ClipboardUtils) {
  @Prop() public assiRecord!: LivingAppsAssignmentRecord;
  @Prop() public reportRecord!: LivingAppsAssignmentRecord;
  @Prop() public editStatus: boolean = false;
  @Prop() public segmentView!: string;
  @Prop() public itemType!: string;
  @Prop() public useRadio!: boolean;

  public get getRecords(): LivingAppsClipboardRecord[] {
    return StoreClipboard.getRecords(this.itemType);
  }

  public get getRecordsForAssis(): ClipboardAssignmentList {
    const records: LivingAppsClipboardRecord[] = StoreClipboard.getRecords(this.itemType);
    const assiSortedRecords: ClipboardAssignmentList = {};
    for (const record of records) {
      if (record.record_id) {
        const recordId = record.record_id.value as string;
        if (assiSortedRecords.hasOwnProperty(`_${recordId}`)) {
          const foundRecord = assiSortedRecords[`_${recordId}`] as ClipboardAssignmentObject;
          foundRecord.items.push(record);
        } else {
          assiSortedRecords[`_${record.record_id.value}`] = {
            record_id: record.record_id.value,
            auf_id: record.auf_id?.value,
            auf_titel: record.auf_titel?.value,
            datum: record.datum?.value,
            items: [record],
          } as ClipboardAssignmentObject;
        }
      }
    }
    return assiSortedRecords;
  }

  public get getSortedAssiRecords(): ClipboardAssignmentObject[] {
    const clipAssiList: ClipboardAssignmentList = this.getRecordsForAssis;
    const sortedClipAssis: ClipboardAssignmentObject[] = [];
    let currentClipAssi: ClipboardAssignmentObject | null = null;
    if (clipAssiList) {
      for (const [k, item] of Object.entries(clipAssiList)) {
        if (Object.prototype.hasOwnProperty.call(clipAssiList, k)) {
          if (this.assiRecord && this.assiRecord.id === k.substring(1)) {
            currentClipAssi = item as unknown as ClipboardAssignmentObject;
          } else {
            sortedClipAssis.push(item as unknown as ClipboardAssignmentObject);
          }
        }
      }
      if (currentClipAssi) {
        sortedClipAssis.unshift(currentClipAssi);
      }
    }
    return sortedClipAssis;
  }

  public get getNotAssignedRecords(): LivingAppsClipboardRecord[] {
    const realRecords: LivingAppsClipboardRecord[] = [];
    const records: LivingAppsClipboardRecord[] = StoreClipboard.getRecords(this.itemType);
    for (const record of records) {
      if (!record.auf_id) {
        realRecords.push(record);
      }
    }
    return realRecords;
  }

  public isCurrentAssi(itemId: any | string): boolean {
    if (itemId) {
      const id: string = typeof itemId === "string" ? itemId : itemId.value;
      if ((this.assiRecord && this.assiRecord.id) === id) {
        return true;
      }
    }
    return false;
  }

  public getRecordById(recordId: string): LivingAppsAssignmentRecord | null {
    return StoreAssignments.getRecordById(recordId);
  }

  public getRecordType(record: LivingAppsClipboardRecord): string {
    const cn = "";
    let type = "audio";
    if (record.notiz?.value && !record.bild?.value && !record.sprachnachricht?.value) {
      type = "note";
    } else if (record.bild?.value) {
      type = "image";
    }
    return `${cn}${type}`;
  }

  public getImageUrl(record: LivingAppsClipboardRecord): string {
    if (record.bild) {
      const image: File | LivingAppsFileControl = record.bild.value as LivingAppsFileControl | File;
      if (image instanceof File) {
        return URL.createObjectURL(image);
      } else {
        return window.location.host.includes("localhost") ? `${HOST_URL}${image.url}` : (image.url as string);
        // return image.url as string;
      }
    }
    return "";
  }

  public getAudio(record: LivingAppsClipboardRecord): File | LivingAppsFileControl | null {
    if (record.sprachnachricht) {
      return record.sprachnachricht.value as File | LivingAppsFileControl;
    }
    return null;
  }

  public handleAssiGroupAutoReports(recordId: string) {
    const assiRecord = StoreAssignments.getRecordById(recordId) as LivingAppsAssignmentRecord;
    const clipItems = this.clipboardItemsForAssi(recordId);
    const sortedSelections = this.sortSelectedItemsByType(clipItems);
    let multiReports = false;
    if (sortedSelections.image.length > 1 || sortedSelections.audio.length > 1) {
      multiReports = true;
    }
    EventBus.$emit("generateReportsAssiSelect", sortedSelections, multiReports, assiRecord);
  }
}
