
import { Component, Vue, Prop, Mixins, Watch } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import {
  LivingAppsConsumptionRecord,
  LivingAppsAssignmentRecord,
  LivingAppsRecordValue,
  GroupedListItems
} from "@/types";
import store, { StoreConsumptions } from "@/store";
import { EventBus } from "@/main";
import { saveConsumptionsRecordMutation } from "../store/graphql/SaveConsumptionsRecord.graphql";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";

@Component({
  components: {
    NoListEntriesMessage
  }
})
export default class GrabBagList extends Mixins(GlobalUser) {
  @Prop({ default: null }) public assignmentRecord!: LivingAppsAssignmentRecord;

  public get groupedList(): GroupedListItems[] {
    const records: LivingAppsConsumptionRecord[] = StoreConsumptions.getRecords;
    const groups: GroupedListItems[] = [];
    for (const record of records) {
      if (
        !record.auftrag_id &&
        ((record.inaktiv2 && record.inaktiv2.value === false) ||
          !record.inaktiv2) &&
        record.offline_id &&
        record.mitarbeiter_id &&
        record.art_id
      ) {
        let found = false;
        for (const group of groups) {
          if (group.groupId === record.mitarbeiter_id.value) {
            group.items.push(record);
            found = true;
          }
        }
        if (!found) {
          const newGroup: GroupedListItems = {
            groupId: record.mitarbeiter_id.value as string,
            items: [record]
          };
          groups.push(newGroup);
        }
      }
    }
    return groups;
  }

  public handleAmount(record: LivingAppsConsumptionRecord) {
    if (this.assignmentRecord) {
      const title: string = "Artikelmenge für";
      const subtitle: string = record.bezeichnung
        ? (record.bezeichnung.value as string)
        : "";
      const max: string = record.artikelmenge
        ? (record.artikelmenge.value as string)
        : "0";
      const desc: string = `Maximal mögliche Menge: ${max}`;
      EventBus.$emit("standardAmount", record, subtitle, desc);
    }
  }

  public getFieldValue(
    name: string,
    record: LivingAppsConsumptionRecord | LivingAppsAssignmentRecord
  ): any {
    if (record) {
      const rep = record[name];
      if (rep) {
        return rep.value;
      }
    }
    return null;
  }

  public laRecordWrapper(value: string | null): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async post(amount: string, record: LivingAppsConsumptionRecord) {
    const assiRecord = this.assignmentRecord;
    const amountNum: number = parseInt(amount, 10) * -1;
    let currentNum: number = parseInt(
      record.artikelmenge ? (record.artikelmenge.value as string) : "0",
      10
    );
    if (currentNum >= amountNum) {
      currentNum += amountNum;
    }
    const data: LivingAppsConsumptionRecord | any = {
      ...record
    };
    data.artikelmenge = this.laRecordWrapper(currentNum.toString());
    await store.dispatch("storeConsumptions/updateRecordData", {
      mutation: saveConsumptionsRecordMutation,
      data,
      app: this,
      eventBus: EventBus
    });
    data.id = null;
    data.auftrag_id = this.laRecordWrapper(
      this.getFieldValue("auftrag_id", assiRecord)
    );
    data.offline_id = null;
    data.auftrag_bezeichnung = this.laRecordWrapper(
      this.getFieldValue("auftrag_bezeichnung", assiRecord)
    );
    data.artikelmenge = this.laRecordWrapper(amountNum.toString());
    await store.dispatch("storeConsumptions/createRecordData", {
      mutation: saveConsumptionsRecordMutation,
      data,
      app: this,
      eventBus: EventBus
    });
  }

  protected mounted() {
    EventBus.$off("grapbagPost").$on("grapbagPost", this.post);
  }
}
