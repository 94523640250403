
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl,
  LivingAppsRecordValue,
  LivingAppsConsumptionRecord
} from "@/types";
import store, { HOST_URL, StoreAuthentication } from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import { Url } from "url";
import { mixins } from "vue-class-component";

@Component
export default class ConsumptionForm extends Mixins(GlobalUser) {
  [index: string]: any;
  @Prop() public assignmentRecord?: LivingAppsAssignmentRecord;
  @Prop() public record?: LivingAppsAssignmentRecord;
  @Prop() public controls?: LivingAppsAssignmentControl | null;
  @Prop() public type!: string;

  public formBeschreibung: string = "";
  public formHours: string = "";

  public isDirty: boolean = false;

  public mounted() {
    this.formBeschreibung = this.getFieldValue("bezeichnung");
    this.formHours = this.getFieldValue("dauer");
  }

  public get beschreibung(): string {
    return this.formBeschreibung;
  }

  public set beschreibung(value: string) {
    this.formBeschreibung = value;
    this.isDirty = true;
  }

  public get hours(): string {
    return this.formHours;
  }

  public set hours(value: string) {
    this.formHours = value;
    this.isDirty = true;
  }

  public getControl(name: string): any | null {
    if (this.controls && name in this.controls) {
      return this.controls[name].control;
    }
    return null;
  }

  public deleteFile(name: string) {
    this[name] = null;
    this.isDirty = true;
  }

  public getFieldValue(name: string, record?: any): any {
    const control = this.getControl(name);
    const rec = record || this.record;
    if (rec && control) {
      const rep = rec[name];
      if (rep) {
        return rep.value;
      }
    }
    return null;
  }

  public laRecordWrapper(value: string | null): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async save() {
    const data: LivingAppsConsumptionRecord | any = {};
    data.auftrag_id = this.laRecordWrapper(
      this.getFieldValue("auftrag_id", this.assignmentRecord)
    );
    data.auftrag_bezeichnung = this.laRecordWrapper(
      this.getFieldValue("auftrag_bezeichnung", this.assignmentRecord)
    );
    const cco = StoreAuthentication.getCostcentreOperator;
    if (cco && cco.ma_id && cco.vorname && cco.nachname) {
      data.mitarbeiter_id = this.laRecordWrapper(cco.ma_id.value as string);
      data.mitarbeitername = this.laRecordWrapper(
        `${cco.vorname.value} ${cco.nachname.value}`
      );
    } else {
      data.mitarbeiter_id = this.laRecordWrapper(this.getUserId());
      data.mitarbeitername = this.laRecordWrapper(this.getUserFullname());
    }
    data.dauer = { value: parseFloat(this.formHours) };
    data.bezeichnung = this.laRecordWrapper(this.beschreibung);
    if (this.record) {
      data.id = this.record.id;
      await store.dispatch("storeConsumptions/updateRecordData", {
        mutation: saveConsumptionsRecordMutation,
        data,
        app: this,
        eventBus: EventBus
      });
    } else {
      await store.dispatch("storeConsumptions/createRecordData", {
        mutation: saveConsumptionsRecordMutation,
        data,
        app: this,
        eventBus: EventBus
      });
    }
  }
}
