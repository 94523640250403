
import { Component, Vue, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import { getModule } from "vuex-module-decorators";
import store, {
  StoreAssignments,
  StoreConsumptions,
  StoreAuthentication
} from "../store";
import {
  LivingAppsAssignmentRecord,
  Route,
  LivingAppsConsumptionRecord
} from "../types";
import MaintenanceDetailData from "@/components/MaintenanceDetailData.vue";
import { saveRecordMutation } from "@/store/graphql/SaveAssignmentRecord.graphql";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import { EventBus } from "@/main";
import Router from "@ionic/vue/dist/types/router";
import localforage from "localforage";
import { RouteEntry } from "@ionic/core";

@Component({
  components: {
    MaintenanceDetailData
  }
})
export default class ActiveAssignment extends Mixins(GlobalUser, Utils) {
  public get activeRecord(): LivingAppsAssignmentRecord | null {
    return StoreAssignments.getScannedRecord;
  }

  public get reportsCount(): string {
    if (this.activeRecord && this.activeRecord.auftrag_id) {
      const assignmentId = this.activeRecord.auftrag_id.value;
      return StoreAssignments.getReports(assignmentId).length;
    }
    return "0";
  }

  public get consumptionsCount(): string {
    if (this.activeRecord && this.activeRecord.auftrag_id) {
      const assignmentId = this.activeRecord.auftrag_id.value;
      return StoreConsumptions.getConsumptionsForAssignment(assignmentId)
        .length;
    }
    return "0";
  }

  public get costcentre() {
    return StoreAuthentication.getCostcentre;
  }

  public handleClick(route: {name: string}) {
    this.$router.push(route);
  }

  public navGoTo(name: string) {
    const record = this.activeRecord as LivingAppsAssignmentRecord;
    const id = record.id;
    const type: string =
      record.typ.value[0].key === "wartung" ? "maintenance" : "repairs";
    let route: Route | undefined;
    if (name === "reports" || name === "consumptions" || name === "employees") {
      if (name === "employees") {
        route = {
          name,
          params: {
            id
          }
        };
      } else {
        route = {
          name,
          params: {
            id,
            type
          }
        };
      }
    } else if (
      name === "new_report" ||
      name === "new_consumptions_time" ||
      name === "new_consumptions_grapbag"
    ) {
      route = {
        name: name === "new_report" ? "reports" : "consumptions",
        params: {
          id,
          type,
          ...(() => {
            if (name === "new_report" || name === "new_consumptions_time") {
              return { edit: true };
            } else {
              return { grapbag: true };
            }
          })()
        }
      };
    }
    if (route) {
      this.$router.push(route);
    }
  }

  public assignmentStatusAction() {
    const assignmentId =
      this.activeRecord && this.activeRecord.auftrag_id
        ? (this.activeRecord.auftrag_id.value as string)
        : "";
    const consumption = StoreConsumptions.getOpenTimeConsumptionForAssignment(
      assignmentId,
      this.getUserId() as string
    );
    EventBus.$emit("newStatus", consumption);
  }

  // public async assignmentFinishAction() {
  //   EventBus.$emit("finishAssignment", this);
  // }
}
