
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { LivingAppsConsumptionRecord } from "@/types";
import { StoreConsumptions } from "@/store";
import { EventBus } from "@/main";

@Component
export default class AmountCard extends Vue {
  @Prop() public record!: LivingAppsConsumptionRecord;
  @Prop() public title!: string;
  @Prop() public subtitle!: string;
  @Prop() public desc!: string;
  @Prop(Function) public dismissModal: any;

  public disable: boolean = false;

  public formAmount: string = "1";

  public get amount() {
    return this.formAmount;
  }

  public set amount(val: string) {
    this.formAmount = val;
  }

  public getValue() {
    return this.amount;
  }

  public cancel() {
    this.disable = true;
    this.dismissModal();
  }

  public post() {
    this.disable = true;
    EventBus.$emit("grapbagPost", this.amount, this.record);
    this.dismissModal();
  }

  @Watch("formAmount")
  public async amountChanged(value: string, olValue: string) {
    const max: number = this.record.artikelmenge ? parseInt(this.record.artikelmenge.value as string, 10) : 0;
    if (parseInt(value, 10) > max) {
      value = max.toString();
    }
    await this.$nextTick();
    this.formAmount = value;
    const input: any = document.getElementsByName("amount")[1];
    if (input) {
      input.value = value;
    }
  }
}
