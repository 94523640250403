
import { Component, Vue, Prop } from "vue-property-decorator";
import { LivingAppsAssignmentRecord, LivingAppsFileControl } from "@/types";
import { HOST_URL } from "@/store";

@Component
export default class ReportsDetailData extends Vue {
  @Prop() public record?: LivingAppsAssignmentRecord;

  public getFileMimetype(): string {
    if (this.record && this.record.upload2) {
      const fileObj: File | LivingAppsFileControl = this.record.upload2.value as File | LivingAppsFileControl;
      if (fileObj instanceof File) {
        return fileObj.type;
      } else if (fileObj.mimetype) {
        return fileObj.mimetype;
      }
    }
    return "";
  }

  public getFileIcon(name: string): string {
    if (this.record) {
      const file = this.record[name];
      if (file && file.value) {
        let mimetype = "";
        if (file.value instanceof File) {
          mimetype = file.value.type as string;
        } else {
          mimetype = file.value.mimetype as string;
        }
        if (mimetype.includes("application/")) {
          if (mimetype.includes("/pdf")) {
            return require("@/assets/pdf-icon.png");
          } else if (mimetype.includes("/msword") || mimetype.includes(".wordprocessingml.document")) {
            return require("@/assets/doc-icon.png");
          } else if (mimetype.includes("/vnd.ms-excel") || mimetype.includes(".spreadsheetml.sheet")) {
            return require("@/assets/xls-icon.png");
          }
          return require("@/assets/unknown-icon.png");
        }
      }
    }
    return "";
  }

  public getFileUrl(name: string): string | null {
    if (this.record) {
      const file = this.record[name];
      if (file && file.value) {
        if (file.value instanceof File) {
          return URL.createObjectURL(file.value);
        } else {
          return HOST_URL + file.value.url;
        }
      }
    }
    return null;
  }
}
