
import GlobalUser from "@/mixins/GlobalUser.vue";
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import { LivingAppsAssignmentRecord } from "@/types";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";

@Component
export default class ClipboardButton extends Mixins(GlobalUser, ClipboardUtils) {
  @Prop(Boolean) public insertFirst!: boolean;
  @Prop(Boolean) public useRadio!: boolean;
  @Prop(Object) public assiRecord!: LivingAppsAssignmentRecord;
  @Prop(Object) public reportRecord!: LivingAppsAssignmentRecord;

  public itemsForAssi(): boolean {
    if (this.assiRecord && this.hasClipboardItemsForAssi(this.assiRecord.id as string)) {
      return true;
    }
    return false;
  }

  public showClipboard() {
    const itemType = "";
    const segmentStart: string = this.itemsForAssi() ? "inAssignment" : "";
    EventBus.$emit(
      "showClipboard",
      this.insertFirst,
      this.assiRecord,
      this.reportRecord,
      itemType,
      this.useRadio,
      segmentStart
    );
  }
}
