
import { Component, Vue, Mixins } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import store, { StoreConsumptions } from "../store";
import GrabBagList from "@/components/GrabBagList.vue";
import ActiveAssignmentButton from "@/components/ActiveAssignmentButton.vue";
import { saveGrapBagRecordMutation } from "@/store/graphql/SaveGrapBagRecord.graphql";
import {
  LivingAppsConsumptionRecord,
  LivingAppsConsumptionControl,
  LivingAppsLookupItem,
  LivingAppsRecordValue,
  StoreScanData
} from "../types";
import { EventBus } from "@/main";

@Component({
  components: {
    GrabBagList,
    ActiveAssignmentButton
  }
})
export default class Employees extends Mixins(GlobalUser, Utils) {
  public storeConsumptions = StoreConsumptions;

  public storeScan(mode: string) {
    const codeType = "barcode";
    let title: string;
    let buttonTitle: string;
    if (mode === "out") {
      title = "Lagerentnahme";
      buttonTitle = "Buchen";
    } else {
      title = "Lagerretoure";
      buttonTitle = "Retoure";
    }
    EventBus.$emit("scanner", "store-scan", mode, title, buttonTitle);
  }

  public getGrapBagDataForId(id: string): LivingAppsConsumptionRecord | null {
    const userSymbol: string | null = this.getUserSymbol();
    let records: LivingAppsConsumptionRecord[] = [];
    if (userSymbol) {
      records = this.storeConsumptions.getRecordsForSymbol(userSymbol);
      for (const record of records) {
        if (record.lap_id && record.lap_id.value === id) {
          return record;
        }
      }
    }
    return null;
  }

  public laRecordWrapper(
    value: string | LivingAppsLookupItem[] | null
  ): LivingAppsRecordValue | null {
    if (value) {
      return { value };
    }
    return null;
  }

  public async reserveArticleFromScan(
    amount: string,
    postMode: string,
    scanData: StoreScanData
  ) {
    const scanRecord = scanData.record as LivingAppsConsumptionRecord;
    if (scanRecord.lap_id) {
      const grapBagData = this.getGrapBagDataForId(scanRecord.lap_id
        .value as string);
      if (grapBagData) {
        const data: LivingAppsConsumptionRecord = { ...grapBagData };
        if (data.artikelmenge) {
          let val = parseInt(data.artikelmenge.value as string, 10);
          const amountNum: number =
            postMode === "out"
              ? parseInt(amount, 10)
              : parseInt(amount, 10) * -1;
          val += amountNum;
          data.artikelmenge = this.laRecordWrapper(val.toString());
          if (val > 0) {
            await store.dispatch("storeConsumptions/updateRecordData", {
              mutation: saveGrapBagRecordMutation,
              data,
              app: this,
              eventBus: EventBus
            });
          } else {
            await store.dispatch("storeConsumptions/deleteRecordData", {
              recordIds: [data.id]
            });
          }
        }
      } else {
        const data: LivingAppsConsumptionRecord = {
          ...scanRecord
        };
        data.id = "";
        data.mitarbeiter_id = this.laRecordWrapper(this.getUserId());
        data.mitarbeitername = this.laRecordWrapper(this.getUserFullname());
        data.artikelmenge = this.laRecordWrapper(amount);
        data.material_geholt_am = this.laRecordWrapper(
          this.getISOWithTimezoneString()
        );
        await store.dispatch("storeConsumptions/createRecordData", {
          mutation: saveGrapBagRecordMutation,
          data,
          app: this,
          eventBus: EventBus
        });
      }
    }
  }

  protected mounted() {
    EventBus.$off("reserveArticleScan").$on(
      "reserveArticleScan",
      this.reserveArticleFromScan
    );
  }
}
