
import { ActionSheetButton, actionSheetController } from "@ionic/core";
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import {
  LivingAppsAssignmentRecord,
  LivingAppsFileControl,
  LivingAppsRecordValue,
  StandardAlertData,
} from "@/types";
import { ClipboardAssignmentList, LivingAppsClipboardRecord } from "@/clipboardTypes";
import store, { HOST_URL, StoreAssignments, StoreClipboard } from "@/store";
import NoListEntriesMessage from "@/components/NoListEntriesMessage.vue";
import ClipboardSegmentItems from "@/components/ClipboardSegmentItems.vue";
import { EventBus } from "../main";
import Utils from "@/mixins/Utils.vue";
import GlobalUser from "@/mixins/GlobalUser.vue";
import ClipboardUtils from "@/mixins/ClipboardUtils.vue";
import { saveClipboardRecordMutation } from "@/store/graphql/SaveClipboardRecord.graphql";
import { permanentClipboardRecordMutation } from "@/store/graphql/PermanentClipboardRecord.graphql";

@Component({
  components: {
    NoListEntriesMessage,
    ClipboardSegmentItems,
  },
})
export default class ClipboardList extends Mixins(Utils, GlobalUser, ClipboardUtils) {
  @Prop() public records!: LivingAppsClipboardRecord[];
  @Prop() public assiRecord!: LivingAppsAssignmentRecord;
  @Prop() public reportRecord!: LivingAppsAssignmentRecord;
  @Prop() public editStatus!: boolean;
  @Prop() public itemType!: string;
  @Prop() public useRadio!: boolean;
  @Prop() public segmentStart!: string;
  @Prop() public fieldName!: string;

  public selectedItems: LivingAppsClipboardRecord[] = [];

  public assiClipboardItems: ClipboardAssignmentList | null = null;

  public listSegmentView: string = "";

  public get segmentView() {
    if (!this.listSegmentView) {
      this.listSegmentView = this.segmentStart || "all";
    }
    return this.listSegmentView;
  }

  public set segmentView(val: string) {
    this.listSegmentView = val;
  }

  public segmentChanged(e: CustomEvent) {
    this.segmentView = e.detail.value;
  }

  public handleItemSelect(e: CustomEvent, record: LivingAppsClipboardRecord) {
    if (this.useRadio) {
      if (e.detail.value) {
        const recordId = e.detail.value.split("_")[0];
        const cRecord = StoreClipboard.getRecordById(recordId);
        if (cRecord) {
          this.selectedItems = [cRecord];
        }
      } else {
        this.selectedItems = [];
      }
    } else {
      const checked: boolean = e.detail.checked;
      const realItems: LivingAppsClipboardRecord[] = [];
      if (checked) {
        for (const item of this.selectedItems) {
          if (item.id === record.id) {
            return;
          }
        }
        this.selectedItems.push(record);
      } else {
        for (const item of this.selectedItems) {
          if (record.id !== item.id) {
            realItems.push(item);
          }
        }
        this.selectedItems = realItems;
      }
    }
  }

  public async handlePermanent(e: CustomEvent, record: LivingAppsClipboardRecord, toFavorite: boolean) {
    const data: LivingAppsClipboardRecord = {};
    const userId = this.getUserId();
    data.id = record.id;
    data.permanent = this.laRecordWrapper(toFavorite);
    await StoreClipboard.updateRecordData({
      mutation: permanentClipboardRecordMutation,
      data,
      app: this,
      eventBus: EventBus,
    });
  }

  public handleClick(route: string, id: string) {
    const rp: any = {
      name: route,
      ...(() => {
        if (id) {
          return { params: { id } };
        }
        return null;
      })(),
    };
    if ((this.$route.params && this.$route.params.id) !== id && this.$router.currentRoute.name !== route) {
      this.$router.push(rp);
    }
    this.$emit("dismiss-modal");
  }

  public async trashAction() {
    if (this.selectedItems.length) {
      const recordIds: string[] = [];
      for (const record of this.selectedItems) {
        recordIds.push(record.id as string);
      }
      await store.dispatch("storeClipboard/deleteRecordData", { recordIds });
      this.selectedItems = [];
    } else {
      const alertData: StandardAlertData = {
        header: "Keine Auswahl!",
        subHeader: "Wählen Sie zuerst die Medien aus, die entfernt werden sollen.",
        message: "",
      };
      EventBus.$emit("standardError", alertData);
    }
  }

  public laRecordWrapper(
    value: string | LivingAppsFileControl | File | boolean | null
  ): LivingAppsRecordValue | null {
    if (value || value === false) {
      return { value };
    }
    return null;
  }

  public async updateItemAssignmentRef(removeRef?: boolean) {
    if (this.selectedItems.length) {
      const selectedItems: LivingAppsClipboardRecord[] = this.selectedItems;
      const data: LivingAppsClipboardRecord = {};
      const userId = this.getUserId();
      for (const item of selectedItems) {
        data.id = item.id;
        data.m_id = this.laRecordWrapper(userId);
        if (removeRef) {
          data.record_id = null;
          data.auf_id = null;
          data.auf_titel = null;
        } else if (this.assiRecord) {
          data.record_id = this.laRecordWrapper(this.assiRecord.id as string);
          data.auf_id = this.assiRecord.auftrag_id;
          data.auf_titel = this.assiRecord.titel;
        }
        if (removeRef || this.assiRecord) {
          await StoreClipboard.updateRecordData({
            mutation: saveClipboardRecordMutation,
            data,
            app: this,
            eventBus: EventBus,
          });
        }
      }
      this.selectedItems = [];
    }
  }

  @Watch("editStatus")
  public async handleEditChange(status: boolean, oldStatus: boolean) {
    if (!status && this.selectedItems.length) {
      if (this.useRadio) {
        StoreClipboard.setAssignedQueueForItem({
          itemType: this.itemType,
          fieldName: this.fieldName,
          record: this.selectedItems[0],
        });
        EventBus.$emit("clipboardSelection", this.selectedItems[0], this.fieldName, this.itemType);
      } else {
        const buttons: ActionSheetButton[] = [
          {
            text: "Entfernen",
            role: "destructive",
            icon: "trash",
            handler: () => {
              this.trashAction();
            },
          },
          ...(this.assiRecord
            ? [
                {
                  text: "An Auftrag heften",
                  icon: "attach",
                  handler: () => {
                    this.updateItemAssignmentRef();
                  },
                },
              ]
            : []),
          ...(() => {
            let foundRef = false;
            for (const item of this.selectedItems) {
              if (item.auf_id) {
                foundRef = true;
                break;
              }
            }
            if (foundRef) {
              const removeRef = true;
              return [
                {
                  text: "Verknüpfung mit Auftrag aufheben",
                  icon: "link",
                  handler: () => {
                    this.updateItemAssignmentRef(removeRef);
                  },
                },
              ];
            }
            return [];
          })(),
          ...(() => {
            const sortedItems = this.sortSelectedItemsByType(this.selectedItems);
            let multiReports: boolean;
            let label: string;
            if (sortedItems.image.length > 1 || sortedItems.audio.length > 1) {
              multiReports = true;
              label = "Berichte aus Auswahl erstellen";
            } else {
              multiReports = false;
              label = "Bericht aus Auswahl erstellen";
            }
            return [
              {
                text: label,
                icon: "paper",
                handler: () => {
                  EventBus.$emit("generateReportsAssiSelect", sortedItems, multiReports);
                  this.selectedItems = [];
                },
              },
            ];
          })(),
          {
            text: "Abbrechen",
            icon: "close",
            role: "cancel",
            handler: () => {
              actionSheet.dismiss();
              this.selectedItems = [];
            },
          },
        ];
        const actionSheet = await actionSheetController.create({
          header: "Was möchten Sie mit der Auswahl tun...",
          buttons,
        });
        await actionSheet.present();
      }
    }
  }
}
