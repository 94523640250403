
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Validation from "@/mixins/Validation.vue";
import { BarcodeFormat, BrowserQRCodeReader } from "@zxing/library";
import { EventBus } from "@/main";
import store, { StoreAssignments, StoreConsumptions, StoreCostcentres } from "@/store";
import {
  LivingAppsConsumptionRecord,
  LivingAppsLookupItem,
  StoreScanData,
  LivingAppsAssignmentRecord,
  StandardAlertData,
} from "../types";

// interface VueData {
//   devices: VideoInputDevice[];
//   currentDevice: VideoInputDevice | null;
//   noDevice: boolean;
// }

@Component
export default class ScanQrCodeElement extends Mixins(GlobalUser, Validation) {
  @Prop() public mode!: string;
  @Prop() public postMode!: string;
  @Prop() public title!: string;
  @Prop() public buttonTitle!: string;
  @Prop(Function) public dismissModal: any;

  public devices: MediaDeviceInfo[] = [];
  public currentDeviceIdx: number = 0;
  public noDevice: boolean = false;
  public currentScannedText: string | null = null;
  public currentScannedData: StoreScanData | null = null;
  public multipleScannedData: LivingAppsAssignmentRecord[] = [];
  public canceled: boolean = false;

  public formAmount: string = "1";
  public formErrors: any = { amount: "" };

  protected codeReader: BrowserQRCodeReader = new BrowserQRCodeReader();

  public get infoForText(): string {
    if (this.mode === "machine-scan" && this.currentScannedText) {
      return StoreCostcentres.infoForCostcentre(this.currentScannedText);
    }
    return "";
  }

  public get errors(): boolean {
    for (const key in this.formErrors) {
      if (this.formErrors.hasOwnProperty(key)) {
        return this.formErrors[key].length ? true : false;
      }
    }
    return false;
  }

  public get amount(): string {
    return this.formAmount;
  }

  public set amount(value: string) {
    this.formAmount = value;
    (async () => {
      this.formErrors.amount = await this.validate(
        value,
        `required|min_value:1|max_value:${this.maxValue()}`,
        "Menge"
      );
    })();
  }

  public get codeForCostcentre(): string {
    if (this.currentScannedText) {
      return StoreCostcentres.codeForCostcentre(this.currentScannedText);
    }
    return "";
  }

  public get articleForStoreId(): LivingAppsConsumptionRecord | null {
    if (this.currentScannedText) {
      return StoreConsumptions.getArticleForStoreId(this.currentScannedText);
    }
    return null;
  }

  public changeCamera(event: any) {
    const cameraIdx = event.detail.value;
    this.canceled = true;
    this.resetScanner();
    this.currentDeviceIdx = cameraIdx;
    localStorage.setItem("camera_index", cameraIdx);
    this.scanCode();
  }

  public setCurrentScannedData(record: LivingAppsAssignmentRecord) {
    const title = record.titel ? (record.titel.value as string) : "Keine Bezeichnung";
    const itemNumber = record.auftrag_id ? (record.auftrag_id.value as string) : "Keine Auftrags-ID";
    this.currentScannedData = {
      title,
      itemNumber,
      record,
    };
  }

  public selectMultipleRecord(record: LivingAppsAssignmentRecord) {
    this.setCurrentScannedData(record);
    this.startScannedAssignment();
  }

  public statusForRecord(record: LivingAppsAssignmentRecord): string {
    if (record.typ.value[0].key !== "bericht") {
      const assignmentId = record.auftrag_id ? (record.auftrag_id.value as string) : "";
      return StoreAssignments.getAssignmentStatus(assignmentId, this.getUserId()) as string;
    }
    return "neu";
  }

  public handleClick(route: string, id?: string) {
    this.scanCancel();
    const rp: any = {
      name: route,
      ...(() => {
        if (id) {
          return { params: { id } };
        }
        return null;
      })(),
    };
    this.$router.push(rp);
  }

  public scanCode() {
    const self = this;
    this.resetScanner();
    this.codeReader.listVideoInputDevices().then((videoInputDevices: MediaDeviceInfo[]) => {
      if (videoInputDevices.length) {        
        this.$data.noDevice = false;
        if (videoInputDevices.length) {
          this.devices = videoInputDevices;
          const deviceId: string = videoInputDevices[this.currentDeviceIdx].deviceId;
          this.codeReader
            .decodeOnceFromVideoDevice(deviceId, "video")
            .then((result) => {
              const barcodeFormat = result.getBarcodeFormat();
              const stdAlertData: StandardAlertData = {
                header: "Fehler beim Scannen!",
                subHeader: "Der gescannte Code ist ungültig.",
                message: "",
              };
              if (
                (this.mode === "machine-scan" || this.mode === "cost-centre-scan") &&
                barcodeFormat === BarcodeFormat.QR_CODE
              ) {
                this.currentScannedText = result.getText();
                if (this.codeForCostcentre) {
                  this.dataForScannedText();
                } else {
                  stdAlertData.message = "Keine Kostenstelle für diesen Code gefunden.";
                  EventBus.$emit("standardError", stdAlertData);
                  self.scanCancel();
                }
              } else if (this.mode === "store-scan" && barcodeFormat === BarcodeFormat.CODE_39) {
                this.currentScannedText = result.getText();
                if (this.articleForStoreId) {
                  this.dataForScannedText();
                } else {
                  stdAlertData.message = "Keinen Artikel für diesen Lagerplatz-Barcode gefunden.";
                  EventBus.$emit("standardError", stdAlertData);
                  self.scanCancel();
                }
              } else {
                stdAlertData.message = "Code ist ungültig oder hat das falsche Format.";
                EventBus.$emit("standardError", stdAlertData);
                self.scanCancel();
              }
              self.codeReader.reset();
            })
            .catch((error) => {
              self.codeReader.reset();
              if (!this.canceled) {
                EventBus.$emit("standardError", {
                  header: "Scannen fehlgeschlagen!",
                  message: "Es hat einen Fehler gegeben. Bitte versuchen Sie es erneut.",
                });
              }
            });
        } else {
          videoInputDevices.forEach((device: MediaDeviceInfo) => {
            window.console.log(`${device.label}, ${device.deviceId}`);
          });
        }
      } else {
        this.noDevice = true;
        this.presentNoDeviceAlert();
      }
    });
  }

  public async scanCancel() {
    this.canceled = true;
    this.resetScanner();
    await this.dismissModal("scanner-component");
  }

  public repeatScan() {
    this.resetScanner();
    this.scanCode();
  }

  public reserveScannedArticle() {
    if (!this.errors) {
      EventBus.$emit("reserveArticleScan", this.amount, this.postMode, this.currentScannedData);
      this.scanCancel();
    }
  }

  public startScannedAssignment() {
    const currentRecord = this.currentScannedData;
    let scannedRecordId;
    if (currentRecord && currentRecord.record) {
      scannedRecordId = currentRecord.record.id;
    }
    if (
      StoreAssignments.getScannedRecord &&
      scannedRecordId &&
      StoreAssignments.getScannedRecord.id !== scannedRecordId
    ) {
      const data: StandardAlertData = {
        header: "Laufender Auftrag!",
        subHeader: "Es ist noch ein Auftrag aktiv.",
        message: `So lange ein Auftrag aktiv ist kann kein weiterer Auftrag
        gestartet werden. Pausieren oder beenden Sie den aktiven Auftrag um einen
        anderen Auftrag starten zu können.`,
      };
      EventBus.$emit("standardError", data);
    } else {
      if (currentRecord) {
        EventBus.$emit("startAssignment", this, currentRecord.record);
      }
    }
    this.scanCancel();
  }

  public resetScanner() {
    this.codeReader.reset();
    this.amount = "1";
    this.currentScannedData = null;
    this.currentScannedText = null;
  }

  public maxValue(): string {
    if (this.currentScannedData) {
      const record = this.currentScannedData.record as LivingAppsConsumptionRecord;
      if (this.postMode === "out") {
        if (record.bestand) {
          return record.bestand.value as string;
        }
      } else if (this.postMode === "in") {
        if (record.artikelmenge) {
          return record.artikelmenge.value as string;
        }
      }
    }
    return "1";
  }

  public getGrapBagDataForId(id: string): LivingAppsConsumptionRecord | null {
    const userSymbol: string | null = this.getUserSymbol();
    let records: LivingAppsConsumptionRecord[] = [];
    if (userSymbol) {
      records = StoreConsumptions.getRecordsForSymbol(userSymbol);
      for (const record of records) {
        if (record.lap_id && record.lap_id.value === id) {
          return record;
        }
      }
    }
    return null;
  }

  public handleAdhocClick(e: Event) {
    const adhoc: LivingAppsAssignmentRecord | null = StoreAssignments.getAdhoc(this.getUserSymbol());
    if (adhoc) {
      EventBus.$emit("newAdhoc", this.currentScannedText);
    } else {
      const stdErrData: StandardAlertData = {
        header: "Kein Sofortauftrag möglich!",
        subHeader: "Anzahl der lokalen Sofortaufträge erschöpft.",
        message: "Führen sie auf der Startseite einen Refresh durch, um das Handy wieder aufzuladen.",
      };
      EventBus.$emit("standardError", stdErrData);
    }
  }

  public handleNewAssignmentClick(e: Event) {
    const adhoc: LivingAppsAssignmentRecord | null = StoreAssignments.getAdhoc(this.getUserSymbol());
    if (adhoc) {
      EventBus.$emit("newAssignment", this.currentScannedText);
    } else {
      const stdErrData: StandardAlertData = {
        header: "Kein neuer Auftrag möglich!",
        subHeader: "Anzahl der lokalen neuen Aufträge erschöpft.",
        message: "Führen sie auf der Startseite einen Refresh durch, um das Handy wieder aufzuladen.",
      };
      EventBus.$emit("standardError", stdErrData);
    }
  }

  public handleArchiveClick(e: Event) {
    let url;
    if (this.currentScannedText && this.mode === "machine-scan") {
      if (store.state.getters.isProduction(store.state)) {
        url = "http://10.149.5.94:8088/wartung_archiv/web/waf.do";
      } else {
        url = "http://10.10.10.76:9999/wartung_archiv/web/waf.do";
      }
      open(`${url}?ks=${this.currentScannedText}`);
    }
  }

  public dataForScannedText() {
    let found: boolean = false;
    if (this.mode === "store-scan") {
      const grapBagRecord = this.getGrapBagDataForId(this.currentScannedText || "");
      if (grapBagRecord) {
        const title = grapBagRecord.bezeichnung
          ? (grapBagRecord.bezeichnung.value as string)
          : "Keine Bezeichnung";
        const itemNumber = grapBagRecord.artikelnummer
          ? (grapBagRecord.artikelnummer.value as string)
          : "Keine Artikelnummer";
        this.currentScannedData = {
          title,
          itemNumber,
          record: grapBagRecord,
        };
        found = true;
      } else {
        const records: LivingAppsConsumptionRecord[] = StoreConsumptions.getRecords;
        for (const record of records) {
          if (!record.auftrag_id && !record.mitarbeiter_id && !record.offline_id) {
            if (
              record.lap_id &&
              record.lap_id.value &&
              (record.lap_id.value as string).replace(/\./g, "") === this.currentScannedText
            ) {
              const title = record.bezeichnung ? (record.bezeichnung.value as string) : "Keine Bezeichnung";
              const itemNumber = record.artikelnummer
                ? (record.artikelnummer.value as string)
                : "Keine Artikelnummer";
              this.currentScannedData = {
                title,
                itemNumber,
                record,
              };
              found = true;
            }
          }
        }
      }
      if (!found) {
        this.currentScannedData = null;
        EventBus.$emit("standardError", {
          header: "Keine Daten!",
          message: "Zu diesem Scan gibt es keine Daten. Bitte setzen sie sich mit dem Lager in Verbindung.",
        });
      }
    } else {
      const realRecords = [];
      const records: LivingAppsAssignmentRecord[] = StoreAssignments.getRecords;
      for (const record of records) {
        const status: string = record.status ? (record.status.value as LivingAppsLookupItem[])[0].key : "";

        if (
          status !== "erledigt" &&
          record.typ &&
          record.typ.value[0].key !== "bericht" &&
          record.anl_id &&
          record.anl_id.value === this.currentScannedText
        ) {
          realRecords.push(record);
        }
      }
      if (realRecords.length && this.mode === "machine-scan") {
        this.multipleScannedData = realRecords;
      } else {
        // this.handleNewAssignmentClick();
        // this.scanCancel();
      }
    }
  }

  public presentNoDeviceAlert() {
    return this.$ionic.alertController
      .create({
        header: "Kamera Fehler",
        message: "Keine Kamera gefunden, Code scannen nicht möglich.",
        buttons: ["OK"],
      })
      .then((a) => {
        a.present();
      });
  }

  protected mounted() {
    const cameraIdx = localStorage.getItem("camera_index");
    this.currentDeviceIdx = cameraIdx ? parseInt(cameraIdx, 10) : 0;
    if (this.mode === "machine-scan" || this.mode === "cost-centre-scan") {
      this.scanCode();
    }
    EventBus.$off("scanCancel").$on("scanCancel", this.scanCancel);
  }
}
