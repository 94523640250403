
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { EventBus } from "@/main";
import {
  LivingAppsAssignmentRecord,
  LivingAppsAssignmentControl,
  LivingAppsLookupItem,
  LivingAppsRecordValue,
  LivingAppsRecordRequiredLookupValue,
  LivingAppsConsumptionRecord,
  AssignmentAdviceData,
} from "@/types";
import store, {
  StoreAssignments,
  StoreAssignmentsAdviceStatus,
  StoreConsumptions,
  StoreLogging,
} from "@/store";
import GlobalUser from "@/mixins/GlobalUser.vue";
import Utils from "@/mixins/Utils.vue";
import { saveRecordMutation } from "@/store/graphql/SaveAssignmentRecord.graphql";
import { saveConsumptionsRecordMutation } from "@/store/graphql/SaveConsumptionsRecord.graphql";
import { mixins } from "vue-class-component";

@Component
export default class AdhocForm extends Mixins(GlobalUser, Utils) {
  [index: string]: any;

  @Prop() public record!: LivingAppsAssignmentRecord;
  @Prop() public machineId!: string;
  @Prop({ default: false }) public newAssignment!: boolean;
  @Prop({ default: "" }) public costcentre!: string;
  @Prop(Function) public dismissModal: any;

  public disable: boolean = false;

  public controls: LivingAppsAssignmentControl | null =
    StoreAssignments.getControls;

  public formTitel: string = "";
  public formBeschreibung: string = "";
  public standardType: LivingAppsLookupItem = {
    key: "reparatur",
    label: "Reparatur",
  };
  public standardStatus: LivingAppsLookupItem = {
    key: "neu",
    label: "Neu",
  };
  public formTyp: LivingAppsLookupItem[] = [this.standardType];

  public isDirty: boolean = false;

  public get filteredTyp(): LivingAppsLookupItem[] {
    const types: LivingAppsLookupItem[] = this.getControl("typ").lookupdata;
    const realTypes: LivingAppsLookupItem[] = [];
    for (const type of types) {
      if (type.key !== "bericht") {
        realTypes.push(type);
      }
    }
    return realTypes;
  }

  public get titel(): string {
    return this.formTitel;
  }

  public set titel(value: string) {
    this.formTitel = value;
    this.isDirty = true;
  }

  public get beschreibung(): string {
    return this.formBeschreibung;
  }

  public set beschreibung(value: string) {
    this.formBeschreibung = value;
    this.isDirty = true;
  }

  public get typ(): LivingAppsLookupItem[] {
    return this.formTyp;
  }

  public set typ(value: LivingAppsLookupItem[]) {
    this.formTyp = value;
    this.isDirty = true;
  }

  public getControl(name: string): any | null {
    if (this.controls && name in this.controls) {
      return this.controls[name].control;
    }
    return null;
  }

  public getLookupItemForFieldWithKey(
    name: string,
    e: any
  ): LivingAppsLookupItem[] | null {
    const control = this.getControl(name);
    const lupItems: LivingAppsLookupItem[] = [];
    let lupItem: LivingAppsLookupItem;
    if (e) {
      for (lupItem of control.lookupdata) {
        if (lupItem.key === e.target.value) {
          lupItems.push(lupItem);
        }
      }
      return lupItems;
    }
    return null;
  }

  public laRecordWrapper(
    value: string | boolean | LivingAppsLookupItem[] | null
  ): LivingAppsRecordValue | null {
    if (value || value === false) {
      return { value };
    }
    return null;
  }

  public cancel(e: Event) {
    this.disable = true;
    this.dismissModal();
  }

  public async setAutoStatusForScannedAssignment() {
    const scannedAssignment = StoreAssignments.getScannedRecord;
    if (scannedAssignment) {
      const assignmentId = scannedAssignment.auftrag_id
        ? (scannedAssignment.auftrag_id.value as string)
        : "";
      const openConsumption = StoreConsumptions.getOpenTimeConsumptionForAssignment(
        assignmentId,
        this.getUserId() as string
      );
      if (openConsumption) {
        const consumptionData = { ...openConsumption };
        consumptionData.mitarbeiterstatus = { value: "Unterbrechung" };
        consumptionData.mitarbeiterstatustext = {
          value: `Neuer Sofortauftrag, Kostenstelle: ${this.machineId}`,
        };
        consumptionData.ende = { value: this.getISOWithTimezoneString() };
        await store.dispatch("storeConsumptions/updateRecordData", {
          mutation: saveConsumptionsRecordMutation,
          data: consumptionData,
          app: this,
          eventBus: EventBus,
        });
        await StoreAssignments.deleteScannedRecord();
      }
    }
  }

  public async save() {
    this.disable = true;
    let message: string;
    if (this.newAssignment) {
      message =
        "Auftragsvorschlag wird übermittelt. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!";
    } else {
      message =
        "Sofortauftrag wird eingerichtet. Bitte warten sie den Vorgang ab und schalten sie das Handy nicht aus!";
    }
    const lc = await this.$ionic.loadingController.create({
      message,
    });
    lc.present();
    try {
      const data = {} as LivingAppsAssignmentRecord;
      const mutation = saveRecordMutation;
      const consumptionMutation = saveConsumptionsRecordMutation;
      if (this.newAssignment) {
        data.adhoc = this.laRecordWrapper(false);
        data.mitarbeiter = this.laRecordWrapper("");
        const assingmentId = this.record.auftrag_id
          ? this.record.auftrag_id.value
          : "";
        const employeeAssignmentBinding: LivingAppsConsumptionRecord | null = StoreConsumptions.getEmployeeAssignmentBinding(
          assingmentId,
          this.getUserId()
        );
        if (employeeAssignmentBinding) {
          await store.dispatch("storeConsumptions/deleteRecordData", {
            recordIds: [employeeAssignmentBinding.id],
            app: this,
            eventBus: EventBus,
          });
        }
      } else {
        data.adhoc = this.laRecordWrapper(true);
        await this.setAutoStatusForScannedAssignment();
      }
      data.titel = this.laRecordWrapper(this.formTitel);
      data.beschreibung = this.laRecordWrapper(this.formBeschreibung);
      data.typ = this.laRecordWrapper(
        this.formTyp
      ) as LivingAppsRecordRequiredLookupValue;
      data.status = this.laRecordWrapper([this.standardStatus]);
      data.angelegt_am = this.laRecordWrapper(this.getISOWithTimezoneString());
      data.angelegt_von = this.laRecordWrapper(this.getUserFullname());
      data.anl_id = this.laRecordWrapper(this.machineId);
      data.id = this.record.id;
      if (this.newAssignment && this.costcentre) {
        const adviceData: AssignmentAdviceData = {
          cdate: new Date().toISOString(),
          record: { ...data },
          assigned: false,
        };
        StoreAssignmentsAdviceStatus.addAssignmentAdvice(adviceData);
      }
      await store.dispatch("storeAssignments/updateRecordData", {
        mutation,
        data,
        app: this,
        eventBus: EventBus,
      });
      lc.dismiss();
      await EventBus.$emit("scanCancel");
      await this.dismissModal();
      if (!this.newAssignment) {
        EventBus.$emit("startAssignment", this, this.record);
      }
      if (this.$route.name !== "main") {
        this.$router.push("main");
      }
    } catch (error) {
      const data: any = {
        title: `Adhoc save (${navigator.onLine ? "Online" : "offline"})`,
        error,
      };
      StoreLogging.push(data);
    }
  }
}
